<template>
  <span class="count-badge">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'CountBadge',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '../assets/base';

.count-badge {
  font-size: 12px;
  font-size: calculate-rem(12px);
  display: block;
  background-color: color(green, 5000);
  color: color(white);
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0 4px;
  border-radius: 9px;
  text-align: center;
  font-weight: 700;
}
</style>
