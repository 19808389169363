function sanitizeTerm(rawTerm) {
  let searchTerm = rawTerm.trim();
  searchTerm = searchTerm.replace(/[^-+a-zA-Z0-9 äüößÄÜÖ]/g, '');
  return searchTerm;
}

const ALLOWED_SECTIONS = ['alle', 'shop', 'möglichkeiten', 'hilfe', 'community'];

function urlifyTermAndSection(rawTerm, rawSection) {
  let section = rawSection.toLowerCase();
  if (!ALLOWED_SECTIONS.includes(section)) {
    section = 'alle';
  }
  const term = sanitizeTerm(rawTerm);
  return {
    name: 'search',
    params: { section },
    query: { term },
  };
}

function deurlifyTerm(urlifiedTerm) {
  return urlifiedTerm.replace('_', ' ');
}

function findPrice(wantedType, prices) {
  for (let i = 0; i < prices.length; i += 1) {
    const price = prices[i];
    if (price.type === wantedType) return price.value;
  }
  return null;
}

function parseThumbImage(teaserImage) {
  if (!teaserImage)
    return '/api/content/images/placeholder/ee06ffe64dfe9ca4d20ee50e1e4b241a/placeholder.png';
  return teaserImage.thumbImagePath;
}

function flattenOfferEntryForSearch(entry) {
  const price = findPrice('DEFAULT', entry.prices);
  const wasPrice = findPrice('WAS', entry.prices);
  const item = {
    type: 'product', // required in frontend, exagon delivers 'faq', 'community' etc
    thumbImageUrl: parseThumbImage(entry.teaserImage),
    title: entry.product.name,
    slug: entry.product.slug,
    description: (entry.usps || []).join('. '),
    price,
    wasPrice,
    href: entry.product.slug,
    deliveryLeadTime: entry.product.deliveryLeadTime,
    offer: entry,
  };

  if (entry.product.stock && entry.product.stock.provider) {
    item.provider = entry.product.stock.provider;
    item.amount = entry.product.stock.amount;
  }
  return item;
}

function goliathifySearchTerm(term) {
  return term
    .split(/  */)
    .map((t) => `${t}*`)
    .join(' ');
}

module.exports = {
  sanitizeTerm,
  urlifyTermAndSection,
  deurlifyTerm,
  flattenOfferEntryForSearch,
  goliathifySearchTerm,
};
