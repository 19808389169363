import { clientWithLoadingIndicator, plainClient } from '@/lib/axios-client/goliath';
import { captureException, captureMessage } from '@/plugins/sentry';

/**
 * Will return the Goliath response or the default response, when an error occurs.
 * All Errors will be logged
 *
 * @param url {string}
 * @param params {object}
 * @param defaultResponse {string | object | array | undefined | null}
 * @param progress {boolean}
 * @param useCache
 * @return {Promise<*[]|*>}
 */
export default async (url, params, defaultResponse = [], progress = true, useCache = true) => {
  const contexts = {
    url: { url },
    params: { params: JSON.stringify(params) },
  };
  const config = {}; // AxiosRequestConfig
  if (!useCache) {
    config.headers = {
      'x-use-cache': false,
    };
  }
  try {
    const axios = progress ? clientWithLoadingIndicator : plainClient;
    const response = await axios.post(url, params, config);

    if (!response) return defaultResponse;
    if (response.data?.errors) {
      if (Array.isArray(response.data.errors)) {
        response.data.errors.forEach((e) => {
          const message = e?.message || 'Goliath Response Error';
          captureMessage(`[Goliath]: ${message}`, {
            contexts,
            extra: e,
          });
        });
      } else {
        captureMessage(response.data.errors, {
          contexts: {
            ...contexts,
            error: response.data.errors,
          },
        });
      }
      return defaultResponse;
    }
    // server has a 500 and returns "internal server error" as body.
    if (typeof response.data === 'string' || typeof response.data === 'undefined') {
      captureMessage(`[Goliath]: ${response.data || 'Returned unexpected response'}`, {
        contexts,
        extra: response,
      });
      return defaultResponse;
    }
    return response.data;
  } catch (e) {
    const status = e.status || e.statusCode || e.response?.status;
    if (status !== 404) {
      captureException(e, {
        contexts,
      });
    }
  }
  return defaultResponse;
};
