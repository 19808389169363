import type { Context } from '@nuxt/types';
import { Axios, type AxiosRequestConfig, type AxiosResponse, AxiosError } from 'axios';
import contentClient from '@/lib/axios-client/content';
import goliathClient from '@/lib/axios-client/goliath';

function setupLoader(client: Axios, store: Context['store']) {
  client.interceptors.request.use((original: AxiosRequestConfig) => {
    store.dispatch('loader/start');
    return original; // This is important as it returns the modified request
  });
  client.interceptors.response.use(
    (original: AxiosResponse) => {
      setTimeout(() => {
        store.dispatch('loader/stop');
      }, 100);
      return original; // This is important as it returns the modified response
    },
    (error: AxiosError) => {
      store.dispatch('loader/stop');
      throw error;
    }
  );
}

export default ({ store }: Context) => {
  setupLoader(contentClient, store);
  setupLoader(goliathClient, store);
};
