<template>
  <LinkButton
    ref="button"
    tag="button"
    class="add-to-cart-button"
    :data-tealium-rel="tealiumRel"
    :disabled="disabled || isDisabled"
    :title="buttonTitle"
    primary
    @click.native.prevent="prepareAddOfferToCart"
  >
    <CountBadge
      v-if="hasQuantityBadge"
      class="add-to-cart-button__count"
      :value="cartQuantity"
    />

    <template v-if="!hideIcon">
      <SvgIcon
        class="add-to-cart-button__icon"
        :image="cartIcon"
      />
    </template>
    <template v-if="displayText">
      <slot> In den Warenkorb</slot>
    </template>
  </LinkButton>
</template>

<script>
import cartIcon from '@/assets/images/navigation/basket.svg';
import Button from '@/mixins/button';
import AddToCart from '@/mixins/add-to-cart';
import CountBadge from '@/components/count-badge.vue';
import { LinkButton } from '@i22-td-smarthome/component-library';

export default {
  name: 'AddToCartButton',
  components: {
    LinkButton,
    CountBadge,
  },
  mixins: [Button, AddToCart],
  props: {
    hideIcon: { type: Boolean },
    displayText: { type: Boolean },
    displayCartQuantity: { type: Boolean },
  },
  emits: ['click'],
  data() {
    return {
      cartIcon,
    };
  },
  computed: {
    hasQuantityBadge() {
      return this.displayCartQuantity && this.cartQuantity > 0;
    },
    buttonTitle() {
      if (this.inStock) {
        if (this.quantity === 1) return 'Artikel in den Warenkorb legen';
        return `${this.quantity} Artikel in den Warenkorb legen`;
      }
      return 'Dieser Artikel ist aktuell leider nicht verfügbar';
    },
  },
  methods: {
    async prepareAddOfferToCart() {
      await this.addOfferToCart();
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-cart-button {
  position: relative;
  --padding-x: 12px;
  --padding-x-sm: 12px;
  pointer-events: all;
  display: flex;
  align-items: center;
  grid-gap: 6px;

  &__count {
    overflow: visible;
    position: absolute;
    top: 1px;
    right: 1px;
  }

  &__icon {
    width: auto;
    height: 100%;
    vertical-align: middle;
  }
}
</style>
