<template>
  <div class="customer-navigation">
    <div class="customer-navigation__links">
      <nuxt-link
        :to="{ name: 'index' }"
        class="customer-navigation__link customer-navigation__link--magenta"
      >
        Telekom Zubehörshop
      </nuxt-link>

      <a
        class="customer-navigation__link"
        href="https://geschaeftskunden.telekom.de/"
        rel="noopener"
        target="_blank"
      >
        Geschäftskunden <SvgIcon :image="externalIcon" />
      </a>
    </div>
    <div class="customer-navigation__links">
      <a
        class="customer-navigation__link"
        href="https://www.telekom.de/start/telekom-shops"
        rel="noopener"
        target="_blank"
      >
        Telekom Shops
      </a>
      <a
        class="customer-navigation__link"
        href="https://www.telekom.de/kontakt"
        rel="noopener"
        target="_blank"
      >
        Kontakt
      </a>
    </div>
  </div>
</template>

<script>
import externalIcon from '@/assets/images/external-link.svg';

export default {
  name: 'CustomerNavigation',
  data() {
    return {
      externalIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.customer-navigation {
  padding-top: $grid-base;
  padding-left: $grid-base;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.4s $nav-timing-function;
  opacity: 1;

  @include mq(false, $mq-lg - 1) {
    display: none;
  }

  @include mq($mq-lg) {
    padding-left: $grid-base * 1.5;
  }

  &__links {
    @include font-size(12px, 14px);
    display: flex;
    gap: 2 * $grid-base;
  }

  &__link {
    color: $color-font;
    display: flex;
    align-items: center;
    gap: 2px;

    svg {
      width: 12px;
      height: 12px;
    }

    &:visited {
      color: $color-font;
    }

    &:hover,
    &:active,
    &:focus {
      color: color(magenta, 1000);
    }

    &--magenta {
      color: color(magenta, 1000);
      font-weight: 700;

      &:visited {
        color: color(magenta, 1000);
      }
    }
  }
}

body.nav-scrolled {
  .customer-navigation {
    @include mq($mq-lg) {
      opacity: 0;
    }
  }
}
</style>
