<template>
  <portal to="globals">
    <div
      v-if="isOpened"
      :class="[containerClass, customClass]"
      class="modal"
    >
      <div
        v-on-clickaway="onClickaway"
        class="modal__inner"
      >
        <button
          ref="closeButton"
          type="button"
          class="modal__close"
          @click.stop="close"
        >
          <SvgIcon
            :image="closeIcon"
            class="modal__close-icon"
          />
        </button>

        <slot />
      </div>
    </div>
  </portal>
</template>

<script>
import { mapMutations } from 'vuex';
import closeIcon from '@/assets/images/menu-close.svg';
import Dismissable from '@/mixins/dismissable';
import Tealium from '@/lib/tealium';

export default {
  name: 'Modal',
  mixins: [Dismissable],
  props: {
    size: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      closeIcon,
    };
  },
  computed: {
    containerClass() {
      return {
        [`modal--${this.size}`]: !!this.size,
      };
    },
  },
  methods: {
    ...mapMutations({
      showBackdrop: 'backdrop/SHOW',
      hideBackdrop: 'backdrop/HIDE',
    }),
    async onOpen(dataTealiumRel) {
      if (dataTealiumRel) {
        Tealium.link({
          wt_link_id: `content.modal.${dataTealiumRel}`,
        });
      }
      this.showBackdrop({ global: true });
      await this.$nextTick();
      const { closeButton } = this.$refs;
      if (closeButton) closeButton.focus();
    },
    onClose() {
      Tealium.link({
        wt_link_id: 'modal.button.close',
      });
      this.hideBackdrop({ global: true });
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

@keyframes modal-open {
  from {
    opacity: 0;
    transform: translateY(-5%) scale(0.75);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal {
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include mq($mq-large) {
    padding: $grid-medium-gutter 0;
  }

  &__inner {
    position: relative;
    min-width: 300px;
    max-width: 700px;
    width: 100%;
    margin: auto;
    padding: 35px 24px 24px;
    background-color: color(white);
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    animation: {
      name: modal-open;
      duration: 200ms;
      timing-function: ease-out;
      iteration-count: 1;
    }
    transform-origin: top center;
    overflow: hidden;
    @include ie-only() {
      overflow-y: scroll;
      max-height: 100%;
    }
  }

  &--large .modal__inner {
    max-width: 1145px;
    min-height: 720px;
    padding: 40px 40px 35px;
  }

  &__close {
    position: absolute;
    text-decoration: none;
    right: 7px;
    top: 7px;
    padding: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: none;
    border: none;
    color: color(grey, 16000);
    outline: none;

    &:hover,
    &:focus {
      color: $color-font;
    }
  }

  &__close-icon {
    height: 24px;
    width: 18px;
  }

  .app--has-vertical-scrollbar & {
    overflow-y: scroll;
  }

  &.image-gallery__modal {
    .modal__inner {
      text-align: center;
    }
  }
}
</style>
