<template>
  <nuxt-link
    to="/shop/warenkorb"
    data-tealium-rel="header.button.warenkorb"
    class="cart-indicator btn btn--transparent btn--borderless"
    active-class="cart-indicator--active"
  >
    <SvgIcon
      no-icon-class
      class="cart-indicator__icon"
      :class="iconClass"
      :image="cartIcon"
    />
    <slot />
    <CountBadge
      v-if="cartQuantity"
      :value="cartQuantity"
      class="cart-indicator__count"
    />
  </nuxt-link>
</template>

<script>
import { mapGetters } from 'vuex';
import cartIcon from '@/assets/images/navigation/basket.svg';
import CountBadge from '@/components/count-badge.vue';

export default {
  name: 'CartIndicator',
  components: {
    CountBadge,
  },
  props: {
    iconClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cartIcon,
    };
  },
  computed: mapGetters('cart', ['cartQuantity']),
};
</script>

<style lang="scss">
@import 'assets/base';

$component: '.cart-indicator';

#{$component} {
  position: relative;
  text-decoration: none;

  &.btn--transparent {
    border-color: transparent;
  }

  &:active,
  &:visited,
  &:hover,
  &:focus {
    color: $color-font;
  }

  &__count {
    font-size: 12px;
    position: absolute;
    right: -7px;
    top: 10px;
  }

  .secondary-navigation &--active {
    color: $color-primary;
  }
}
</style>
