<template>
  <div
    ref="dialogContainer"
    :class="containerClass"
    :style="containerCssStyle"
    class="dialog-container"
  >
    <Flyout
      ref="flyout"
      :dismissable="isDismissable"
      :type="type"
      class="dialog-container__flyout"
      @closed="onClosed"
      @open="onOpen"
    >
      <component
        :is="component"
        v-if="component"
        :dialog-options="{ type, isDismissable }"
        :options="componentOptions"
        class="dialog-content"
        @close="closeFromComponent"
      />
    </Flyout>
  </div>
</template>

<script>
import { isEqual, isNull, isUndefined } from 'lodash';
import { mapActions } from 'vuex';
import AddToCartDialogContent from '@/components/add-to-cart-dialog-content.vue';
import ConfirmationDialogContent from '@/components/confirmation-dialog-content.vue';
import Dialog from '@/helpers/dialog';
import Flyout from '@/components/flyout.vue';
import InfoDialogContent from '@/components/info-dialog-content.vue';

// Register your content components here. That is needed to avoid circular
// imports.
const CONTENT_COMPONENTS = {
  AddToCartDialogContent,
  ConfirmationDialogContent,
  InfoDialogContent,
};

export default {
  name: 'DialogContainer',
  components: {
    Flyout,
    ...CONTENT_COMPONENTS,
  },
  props: {
    withNav: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'open'],
  data() {
    return {
      component: null,
      componentOptions: {},
      type: null,
      isDismissable: true,
      callback: null,
      result: null,
      defaultResult: true,
      flyoutComponent: null,
    };
  },
  computed: {
    containerClass() {
      if (!this.withNav) return {};
      return {
        'dialog-container--is-opened': this.displayState === 'opened',
        'dialog-container--with-nav': true,
      };
    },
    displayState() {
      if (!this.flyoutComponent) return null;
      return this.flyoutComponent.displayState;
    },
    containerCssStyle() {
      return {
        '--topDesktop': '96px',
        '--topMobile': '60px',
      };
    },
    isOpened() {
      if (!this.flyoutComponent) return false;
      return this.flyoutComponent.isOpened;
    },
    closeResult() {
      if (isNull(this.result) || isUndefined(this.result)) {
        return this.defaultResult;
      }
      return this.result;
    },
  },
  watch: {
    $route(route, prevRoute) {
      // Dialogs are closed when route change is enforced. Confirmations will
      // be cancelled.
      if (!isEqual(route, prevRoute) && this.isOpened) {
        this.close();
      }
    },
  },
  created() {
    Dialog.register(this);
  },
  updated() {
    Dialog.register(this);
  },
  mounted() {
    this.flyoutComponent = this.$refs.flyout;
  },
  destroyed() {
    Dialog.unregister();
  },
  methods: {
    ...mapActions('cart', ['clearCart']),
    open() {
      this.flyoutComponent.open();
    },
    close() {
      this.flyoutComponent.close();
    },
    async show(component, options) {
      await this.hide();
      const promise = this.registerCallback();
      this.setData(component, options);
      this.open();
      return promise;
    },
    hide() {
      if (!this.isOpened) return true;
      const promise = this.registerCallback();
      this.close();
      return promise;
    },
    onOpen() {
      this.$emit('open');
    },
    onClosed() {
      this.$emit('closed');
      if (this.callback) {
        this.callback(this.closeResult);
      }
      this.resetData();
      this.unregisterCallback();
    },
    closeFromComponent(result) {
      this.result = result;
      if (this.result === 'procedureError') {
        this.clearCart();
        this.$router.push('/');
      }
      this.close();
    },
    setData(component, options) {
      Object.keys(options).forEach((key) => {
        this[key] = options[key];
      });
      this.component = component;
    },
    resetData() {
      this.component = null;
      this.componentOptions = {};
      this.type = null;
      this.isDismissable = true;
      this.result = null;
      this.defaultResult = true;
    },
    registerCallback() {
      return new Promise((resolve) => {
        this.callback = resolve;
      });
    },
    unregisterCallback() {
      this.callback = null;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

.dialog-container {
  z-index: $z-index-dialog;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;

  .app--has-vertical-scrollbar.app--has-global-backdrop & {
    right: $scrollbar-size;
  }

  &--is-opened {
    height: 100vh;
    cursor: not-allowed;
  }

  &--with-nav {
    top: var(--topMobile);
    @include mq($mq-lg) {
      top: var(--topDesktop);
    }

    @include ie-only() {
      padding-top: 136px;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 12px;
    top: 5px;
    @include mq($mq-medium) {
      position: relative;
      left: 0;
      top: 2px;
      margin-right: 8px;
    }
  }

  &__recommendations {
    width: 100%;
    margin-top: 2 * $grid-base;
    justify-content: center;
  }
}

.dialog-content {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__header {
    position: relative;
    text-align: left;
    padding-right: 2.5 * $grid-base;
    padding-left: 2.5 * $grid-base;
    padding-bottom: $grid-base;
    @include mq($mq-medium) {
      text-align: center;
    }
  }

  &__buttons {
    width: 100%;
  }

  &__button {
    margin-top: $grid-base;
    margin-bottom: $grid-base;
    height: auto;
    padding: 12px;
  }

  &__add-to-cart-message {
    color: $color-font;
  }
}
</style>
