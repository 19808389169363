
import { type PropType } from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import { defineComponent } from '@nuxtjs/composition-api';
import closeIcon from '@/assets/images/menu-close.svg';
import Dismissable from '@/mixins/dismissable';

export default defineComponent({
  data() {
    return { closeIcon };
  },
  props: {
    excludedRoutes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    excludedSubroutes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  mixins: [Dismissable],
  watch: {
    $route() {
      this.close();
      this.showOverlay();
    },
  },
  mounted() {
    this.close();
    this.showOverlay();
  },
  computed: {
    ...mapGetters('backdrop', ['isActive', 'isGloballyActive']),
    dateToday() {
      return new Date().getTime();
    },
  },
  beforeDestroy() {
    localStorage.removeItem('POPUP');
  },
  methods: {
    ...mapMutations({
      showBackdrop: 'backdrop/SHOW',
      hideBackdrop: 'backdrop/HIDE',
    }),
    onOpen() {
      this.showBackdrop();
    },
    onClose() {
      this.hideBackdrop();
      localStorage.removeItem('POPUP');
    },
    showOverlay() {
      // some modal is Active
      if (this.isActive || this.isGloballyActive) return;

      // check excluded routes:
      if (
        this.excludedRoutes.some((route: string) => this.$route.path.startsWith(route)) ||
        this.excludedSubroutes.includes(this.$route.path)
      ) {
        return;
      }

      // Check Cookie Consent
      const consentState = window.utag?.gdpr?.getConsentState?.();
      const seenConsentAgreement =
        (consentState && consentState === 0) || !this.$config.mode.isProduction; // 0 = pending; -1 = rejected; 1 = given
      if (!seenConsentAgreement) return;

      const today = new Date();
      const todayDateString = today.toISOString().split('T')[0];

      const lastShownDate = localStorage.getItem('POPUP_DATE');
      if (lastShownDate !== todayDateString) {
        setTimeout(() => {
          this.open();
        }, 1000);
        localStorage.setItem('POPUP', 'open');
        localStorage.setItem('POPUP_DATE', todayDateString);
      }
    },
  },
});
