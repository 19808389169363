import { render, staticRenderFns } from "./add-to-cart-button.vue?vue&type=template&id=504652c0&scoped=true"
import script from "./add-to-cart-button.vue?vue&type=script&lang=js"
export * from "./add-to-cart-button.vue?vue&type=script&lang=js"
import style0 from "./add-to-cart-button.vue?vue&type=style&index=0&id=504652c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504652c0",
  null
  
)

export default component.exports