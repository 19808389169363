export default {
  getStockFromOffer(offer) {
    const { product } = offer;

    if (product?.availability) {
      return product.availability?.stock || 0;
    }

    if (product?.stock) {
      // The stock field is deprecated, we leave it here for the moment to be
      // backward compatible
      return product.stock?.amount || 0;
    }

    return Infinity;
  },
};
