import type { Plugin } from '@nuxt/types';
import useScreenSize from '@/composables/use-screen-size';

const screenSizePlugin: Plugin = (context, inject) => {
  const screenSizeState = useScreenSize();

  // Attach resize event listener only on client-side
  if (process.client) {
    setTimeout(() => {
      screenSizeState.updateScreenSize();
      window.addEventListener('resize', () => {
        screenSizeState.updateScreenSize();
      });
    }, 1);
  }

  inject('screenSize', screenSizeState);
};

export default screenSizePlugin;
