import { getPriceFormatted as getPriceFormattedRocket, defaultPriceOptions } from '@i22/rocket';
import type { Options, Price as RocketPrice } from '@i22/rocket/dist/price-utils/types';
import { getRebatePercentage, getRebatePrices } from '@/lib/rebate-helper';
import type { Rebate } from '@/store/offers';
import type { Offer, PaymentFrequency, Price, PriceType } from '@/lib/goliath/goliath.types';

function getPricesFromOfferWithRebate(offer: Offer, rebate: Rebate, quantity = 1) {
  if (!rebate?.staggereds || !offer.prices) {
    return offer.prices;
  }

  const rebatePercentage = getRebatePercentage(rebate.staggereds, quantity);

  return getRebatePrices(offer.prices as Price[], rebatePercentage);
}

function getPriceFormatted(
  prices: Price[],
  type: PriceType,
  paymentFrequency: PaymentFrequency = 'ONETIME' as PaymentFrequency.Onetime,
  options: Options = defaultPriceOptions
) {
  return getPriceFormattedRocket(prices as RocketPrice[], type, paymentFrequency, options);
}

function getPriceFromOfferFormatted(
  offer: Offer,
  type: PriceType,
  paymentFrequency: PaymentFrequency = 'ONETIME' as PaymentFrequency.Onetime,
  options: Options = defaultPriceOptions
) {
  if (!offer.prices) return undefined;
  return getPriceFormatted(offer.prices as Price[], type, paymentFrequency, options);
}

export { getPricesFromOfferWithRebate, getPriceFormatted, getPriceFromOfferFormatted };
