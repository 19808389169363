<template>
  <BaseLayout
    class="layout--minimal"
    theme-header="small"
    theme-footer="small"
  />
</template>

<script>
import Layout from '@/mixins/layout';
import BaseLayout from '@/components/base-layout.vue';

export default {
  name: 'MinimalLayout',
  components: {
    BaseLayout,
  },
  mixins: [Layout],
};
</script>

<style lang="scss" scoped>
.layout--minimal {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  :deep(.layout__container) {
    flex: 1 1 100%;
  }
}
</style>
