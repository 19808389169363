<template>
  <div class="footer-featured-brands">
    <div
      v-for="(group, index) in groups"
      :key="`group-${index}`"
      class="footer-featured-brands__column"
    >
      <p class="text text--h4 footer-featured-brands__headline">
        {{ group.title }}
      </p>
      <ul class="footer-featured-brands__brands list">
        <li
          v-for="(entry, entryIndex) in group.entries"
          :key="`entry-${index}-${entryIndex}`"
          class="footer-featured-brands__brand list__element"
        >
          <nuxt-link
            :to="entry.url"
            class="link--secondary"
          >
            {{ entry.title }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterFeaturedBrands',
  data() {
    return {
      groups: [
        {
          title: 'Smartphones',
          entries: [
            {
              title: 'Apple',
              url: '/smartphones-und-handys/_apple',
            },
            {
              title: 'Samsung',
              url: '/smartphones-und-handys/_samsung',
            },
            {
              title: 'XIAOMI',
              url: '/smartphones-und-handys/_xiaomi',
            },
          ],
        },
        {
          title: 'Tablets',
          entries: [
            {
              title: 'Apple',
              url: '/tablets-und-surfsticks/tablets/_apple',
            },
            {
              title: 'Samsung',
              url: '/tablets-und-surfsticks/tablets/_samsung',
            },
          ],
        },
        {
          title: 'Zubehör',
          entries: [
            {
              title: 'Apple',
              url: '/zubehoer/_apple',
            },
            {
              title: 'Anker',
              url: '/zubehoer/_anker',
            },
            {
              title: 'agood',
              url: '/zubehoer/_agood',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.footer-featured-brands {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mq(800px) {
    flex-direction: row;
  }

  &__brand {
    @include font-size(16px, 27px);
  }

  &__brands {
    list-style: none;
  }

  &__column {
    width: 32%;
  }

  &__headline {
    color: color(white);
    @include font-size(15px, 30px);
    font-weight: 700;
    text-align: left;
    margin: 0 0 12px 0;
    position: relative;
    text-transform: uppercase;
    @include mq($mq-sm) {
      color: $color-secondary;
      @include font-size(16px, 18px);
    }
  }
}
</style>
