import cloneDeep from 'lodash/cloneDeep';
import { mode } from '@/lib/public-runtime-config';
import { slugify } from '@i22-td-smarthome/component-library';

const TIMEOUT = 1000;
const MAX_ITERATIONS = 10;

export type TealiumData = {
  page_type?: 'theme' | 'theme.landing' | string;
  page_content_id?: string;
  login_status?: 'not-logged-in';
  shop_customer_type?: 'bestandskunde' | 'nicht-bestandskunde';
  shop_mlid?: string;
  shop_vb?: string;
  shop_vo?: 'ESH37' | string;
  shop_vpnr?: string;
  shop_gutschein_kampagne?: string;
  shop_gutschein_vorhanden?: 'ja' | 'nein';
  wt_link_id?: string;
} & Record<string, any>;

export type Utag = {
  view: (data: TealiumData, cb?: () => any, uids?: any) => void;
  link: (data: TealiumData, cb?: () => any, uids?: any) => void;
  gdpr?: {
    getConsentState?: () => number;
    getCategories?: () => ('social' | string)[];
  };
};

declare global {
  interface Window {
    utag?: Utag;
    wt?: any;
    wtm?: any;
  }
}

let trackingTimeout: ReturnType<typeof setTimeout> | null = null;
let trackingQueue: { action: 'view' | 'link'; data: TealiumData }[] = [];

const waitForUtag = (iteration = 0): Promise<void> =>
  new Promise((resolve, reject) => {
    if (iteration > MAX_ITERATIONS) {
      reject(new Error(`Tealium not available within ${TIMEOUT * MAX_ITERATIONS} ms`));
      return;
    }

    if (window.utag) {
      resolve();
      return;
    }
    trackingTimeout = setTimeout(() => {
      waitForUtag(iteration + 1)
        .then(resolve)
        .catch(reject);
    }, TIMEOUT);
  });

const consumeQueue = async (): Promise<void> => {
  if (trackingTimeout) {
    clearTimeout(trackingTimeout);
  }

  try {
    await waitForUtag();

    trackingQueue.forEach(({ action, data }) => {
      if (mode.isDevelopment) {
        // eslint-disable-next-line no-console
        console.warn('Tealium', action, data);
      }
      window.utag?.[action](data);
    });
    trackingQueue = [];
  } catch (error) {
    console.error(error);
  }
};

function track(action: 'view' | 'link', data: TealiumData): Promise<void> {
  try {
    // We need to clone data as Tealium manipulates the object and therefore may
    // be able to change data in our components!
    const clonedData = cloneDeep(data);
    ['page_content_id', 'wt_link_id'].forEach((key) => {
      if (!clonedData[key]) return;
      clonedData[key] = slugify(clonedData[key], true);
    });

    trackingQueue.push({
      action,
      data: clonedData,
    });
    return consumeQueue();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.info(error);
    return Promise.resolve(); // always resolve. We do not want to crash the whole page!
  }
}

export type TealiumObject = {
  view: (data: TealiumData) => Promise<void>;
  link: (data: TealiumData | string) => Promise<void>;
  updateAutomaticLinkTracking: () => void;
};

const index: TealiumObject = {
  view(data) {
    return track('view', data);
  },
  link(data) {
    if (typeof data === 'string') {
      return track('link', { wt_link_id: data });
    }
    return track('link', data);
  },
  updateAutomaticLinkTracking() {
    if (window.wt) {
      window.wt.linkTrackInit();
    }
    if (window.wtm) {
      window.wtm.linkTrackInit();
    }
  },
};

export default index;
