<template>
  <div
    class="subnav-list"
    :class="{ 'subnav-list--expanded': expanded }"
  >
    <NavigationItem
      v-for="navigationItem in items"
      :key="navigationItem.path"
      :navigation-item="navigationItem"
      :level="2"
      @subnav-open="$emit('subnav-open', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'SubNavigationList',
  components: {
    NavigationItem: () => import('@/components/navigation/navigation-item.vue'), // its recursive
  },
  props: {
    expanded: { type: Boolean },
    items: {
      type: Array,
      default: () => [],
    },
    categoryPath: {
      type: String,
      default: () => '',
    },
  },
  emits: ['subnav-open'],
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.subnav-list {
  @include mq(false, $mq-lg - 1) {
    margin-bottom: $grid-base * 6;
  }

  @include mq($mq-lg) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    grid-gap: 0 96px;
    flex-wrap: wrap;
    max-height: 380px;
  }

  &--expanded {
    @include mq(false, $mq-lg - 1) {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
