import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import ShopApi from '@/lib/shop-api';
import type { RootState } from '@/types/store-types';

export type ReductionMethod =
  | 'RS' // Discount amount, single unit (Voucher)
  | 'RT' // Discount amount, single unit (Coupon)
  | 'RP' // Percent
  | 'PS'; // New price, single unit

export type BaseCoupon = {
  description?: string;
  reductionMethodCode?: ReductionMethod;
  status: 'empty' | 'VALID' | 'PARTIALREDEEMED' | 'brandError' | 'INVALID' | 'EXPIRED';
  productNumbersWhitelist?: string[];
  productNumbersBlacklist?: string[];
  providersWhitelist?: ('EGSolo_Brodos' | 'EGSolo_Telekom')[];
  providersBlacklist?: ('EGSolo_Brodos' | 'EGSolo_Telekom')[];
  reductionValue?: number;
  type?: 'coupon' | 'voucher';
  typeCode?: '01' | '02'; // 01: apply-able on 1 product;
  initialValue?: number;
};

export interface Coupon extends BaseCoupon {
  couponCode: string;
  expiryDate?: string;
  initialValue?: number;
}

export interface RedeemedCouponResponse extends BaseCoupon {
  roundingMethodCode: string;
  multiple: string;
}

export interface CouponState {
  coupon: Coupon;
}

interface CouponGettersTypes {
  couponInfo: Coupon;
  compatibleArticleNumbers: string[];
  incompatibleArticleNumbers: string[];
  compatibleProductProviders: ['BRODOS'] | ['TELEKOM'] | [];
  isValidCoupon: boolean;
  errorStatus: string;
  reductionValue: string | number | undefined;
}

export type CouponGetterTree = {
  [P in keyof CouponGettersTypes]: CouponGettersTypes[P];
};

export type CouponGetters = {
  [P in keyof CouponGettersTypes]: (
    state: CouponState,
    getters: CouponGetterTree
  ) => CouponGettersTypes[P];
};

export const state = (): CouponState => ({
  coupon: {
    couponCode: '',
    status: 'empty',
  },
});

export const mutations: MutationTree<CouponState> = {
  SET_COUPON(state, coupon) {
    state.coupon = coupon;
  },
  ERASE_COUPON(state) {
    state.coupon = {
      couponCode: '',
      status: 'empty',
    };
  },
  SET_ERROR(state, errorType) {
    state.coupon.status = errorType;
  },
};

export const actions: ActionTree<CouponState, RootState> = {
  async initializeCoupon(context, redeemedCoupon: string) {
    try {
      if (redeemedCoupon && redeemedCoupon !== context.getters.couponInfo.couponCode) {
        const data = await ShopApi.getCouponInfo(redeemedCoupon);
        context.commit('SET_COUPON', {
          ...data.couponVoucher,
          couponCode: redeemedCoupon,
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateCoupon(context, couponCode: string) {
    try {
      const data = await ShopApi.getCouponInfo(couponCode);
      context.commit('SET_COUPON', {
        ...data.couponVoucher,
        couponCode: ['valid', 'partialredeemed'].includes(data.couponVoucher.status.toLowerCase())
          ? couponCode
          : '',
      });
      await context.dispatch('cart/fetchCart', null, { root: true });
    } catch (e) {
      console.error(e);
    }
  },
  async clearCoupon(context, isCart: boolean) {
    if (!context.getters.couponInfo?.couponCode) return;
    context.commit('ERASE_COUPON');
    // if the coupon is erased in the treueshop and not in the cart
    if (!isCart) {
      await context.dispatch('cart/deleteCoupon', null, { root: true });
    }
  },
  reportBrandError(context) {
    context.commit('SET_ERROR', 'brandError');
  },
};

export const getters: GetterTree<CouponState, RootState> & CouponGetters = {
  couponInfo(state) {
    return state.coupon;
  },
  compatibleArticleNumbers(state) {
    return state.coupon.productNumbersWhitelist || [];
  },
  incompatibleArticleNumbers(state) {
    return state.coupon.productNumbersBlacklist || [];
  },
  compatibleProductProviders(state) {
    if (
      state.coupon.providersWhitelist?.includes('EGSolo_Brodos') &&
      state.coupon.providersWhitelist?.includes('EGSolo_Telekom')
    ) {
      return [];
    }

    if (
      state.coupon.providersWhitelist?.includes('EGSolo_Telekom') ||
      state.coupon.providersBlacklist?.includes('EGSolo_Brodos')
    ) {
      return ['TELEKOM'];
    }
    if (
      state.coupon.providersWhitelist?.includes('EGSolo_Brodos') ||
      state.coupon.providersBlacklist?.includes('EGSolo_Telekom')
    ) {
      return ['BRODOS'];
    }
    return [];
  },
  isValidCoupon(state) {
    return ['valid', 'partialredeemed'].includes(state.coupon.status?.toLowerCase());
  },
  errorStatus(state, getters) {
    return !getters.isValidCoupon ? state.coupon.status?.toLowerCase() : '';
  },
  reductionValue(state) {
    return state.coupon.reductionValue && state.coupon.reductionValue % 1 !== 0
      ? state.coupon.reductionValue.toFixed(2)
      : state.coupon.reductionValue;
  },
};
