<template>
  <OfferTile
    :offer="offer"
    :tag-title="tagTitle"
    link-tag="nuxt-link"
    :rectangular-badge-hex="leftFlagColor"
    :class="{ 'offer-tile--treueShop': hasTreueshopFlag, 'offer-tile--discounted': !!discount }"
  >
    <template #image>
      <GoliathImageElement
        v-if="imageSources"
        :alt="title"
        :sources="imageSources"
      />
    </template>
    <template
      v-if="offer.isNew"
      #rectangularBadge
    >
      NEU
    </template>
    <template
      v-else-if="!!discount"
      #rectangularBadge
    >
      %
    </template>
    <template
      v-else-if="rebateDescription"
      #rectangularBadge
    >
      AKTION
    </template>
    <template
      v-else-if="hasHighlightFlag"
      #rectangularBadge
    >
      Bestseller
    </template>

    <template
      v-if="hasTreueshopFlag"
      #highlightFlag
    >
      {{ treueShopFlagText }}
    </template>
    <template
      v-else-if="hasZeroPercentFinancing"
      #highlightFlag
    >
      <button
        ref="zeroPercentFinancingInfoLink"
        class="offer-tile__zero-percent-financing"
        data-tealium-rel="content.button.zero-percent-financing-legal"
        @click.prevent="$refs.zeroPercentFinancingModal.open()"
      >
        0% Finanzierung<sup>*</sup>
      </button>
      <Modal
        ref="zeroPercentFinancingModal"
        @close="$refs.zeroPercentFinancingInfoLink.focus()"
      >
        <p class="offer-tile__zero-percent-financing-text">
          Ratenfinanzierung wählbar ab 99 € bis 5.000 €. Finanzierungssumme 699,00 €, monatliche
          Rate ab 58,25 €; Barzahlungspreis entspricht dem Nettodarlehensbetrag. Der effektive
          Jahreszins von 0 % (Nur bei einer Laufzeit von maximal 12 Monaten) entspricht einem festen
          Sollzinssatz von 0 % p.a. Erst- und Schlussrate kann abweichen. Bonität vorausgesetzt. Die
          Angaben stellen zugleich das repräsentative 2/3-Bsp. i.S.d. § 6a IV PAngV dar. Höhe und
          Anzahl der monatlichen Raten können sich verändern, wenn weitere Verfügungen über den
          Kreditrahmen vorgenommen werden. Vermittlung erfolgt ausschließlich für unsere
          Finanzierungspartner: Novalnet AG, Feringastr. 4, 85774 Unterföhring. Alle weiteren
          Informationen zum Thema Ratenzahlung findest Du unter
          <LinkButton
            tag="nuxt-link"
            to="/ratenzahlung"
            text
            >https://shop.telekom.de/ratenzahlung</LinkButton
          >
        </p>
      </Modal>
    </template>
    <template #brand>
      <CdnImage
        v-if="brand.logo"
        :class="['offer-tile__logo-image', `offer-tile__logo-image--${brand.slug}`]"
        render-without-figure
        :src="brand.logo"
        :alt="brand.name"
        v-bind="logoWidthAttribute"
        :lazy="lazy"
      />
      <span v-else>{{ brand.name }}</span>
    </template>

    <template
      v-if="telekomContractLink"
      #offerContract
    >
      <div class="offer-tile__contract-wrapper">
        <LinkButton
          class="offer-tile__contract-link"
          tag="button"
          no-styling
          @click.native.prevent="clickContractLink"
        >
          {{ contractLinkText }}
        </LinkButton>
      </div>
    </template>
    <template #energyLabel>
      <EnergyLabelContainer
        class="offer-tile__energy-label"
        :product="offer.product"
        datasheet-alignment="justify"
      />
    </template>
    <template #alternatives>
      <div
        v-if="isBundle"
        class="offer-tile__variants"
      >
        <AlternativeOffersCount :product-alternatives="offer.productAlternatives" />
      </div>
    </template>
    <template #info>
      <div
        v-if="inStock"
        class="offer-tile__delivery"
      >
        <span v-if="deliveryLeadTime">
          Lieferzeit: <span class="text text--nowrap">{{ deliveryLeadTime }}</span>
        </span>
      </div>
      <div
        v-else
        class="offer-tile__unavailable"
      >
        {{ availability.text }}
      </div>
      <div
        v-if="hasCartButton"
        class="offer-tile__add-to-cart"
      >
        <AddToCartButton
          display-cart-quantity
          :offer="offer"
        />
      </div>
      <client-only v-if="isBookmarkingAvailable">
        <LinkButton
          class="offer-tile__bookmark"
          :class="{ 'offer-tile__bookmark--active': isBookmarked }"
          tag="button"
          transparent
          @click.native.prevent="toggleBookmarkNumber"
        >
          <svg-icon
            no-icon-class
            class="offer-tile__bookmark-icon"
            :image="IconBookmark"
          />
        </LinkButton>
      </client-only>
    </template>
  </OfferTile>
</template>

<script>
import { getTelekomContractLink, getTelekomContractLinkText } from '@/lib/telekom-contract-link';
import {
  OfferTile,
  GoliathImageElement,
  LinkButton,
  availabilityCalculator,
} from '@i22-td-smarthome/component-library';
import { getDiscount, getPrice } from '@i22/rocket';
import Tealium from '@/lib/tealium';
import Modal from '@/components/modal.vue';
import CdnImage from '@/components/cdn-image.vue';

import AlternativeOffersCount from '@/components/alternative-offers-count.vue';
import EnergyLabelContainer from '@/components/energy-label-container.vue';
import AddToCartButton from '@/components/add-to-cart-button.vue';

import { mapGetters } from 'vuex';
import { getBrand } from '@/lib/brand';

import IconBookmark from '@/assets/images/icon-bookmark.svg';
import useProductBookmarks from '@/composables/product-bookmarks';

const PROVIDER_NAMES = {
  BRODOS: 'Brodos AG',
  TELEKOM: 'Telekom Deutschland GmbH',
  TVPP: 'Telekom Deutschland GmbH',
};

const HIGHLIGHTS_OFFER = [
  'DCS8302LH-SPK',
  '4251281645534',
  '8718699787059',
  '4251281641420',
  '4251281645138',
];

export default {
  name: 'OfferTileContainer',
  setup() {
    const { isProductInBookmarks, isBookmarkingAvailable, productBookmarks, toggleBookmark } =
      useProductBookmarks();
    return { isProductInBookmarks, isBookmarkingAvailable, productBookmarks, toggleBookmark };
  },
  components: {
    OfferTile,
    AlternativeOffersCount,
    GoliathImageElement,
    EnergyLabelContainer,
    LinkButton,
    Modal,
    AddToCartButton,
    CdnImage,
  },
  props: {
    tagTitle: { type: String, default: 'h2' },
    offer: { type: Object, required: true },
    hasCartButton: { type: Boolean },
    lazy: { type: Boolean, default: false },
  },
  data() {
    return {
      IconBookmark,
    };
  },
  computed: {
    ...mapGetters('coupon', [
      'couponInfo',
      'isValidCoupon',
      'incompatibleArticleNumbers',
      'compatibleArticleNumbers',
      'compatibleProductProviders',
      'reductionValue',
    ]),
    ...mapGetters('offers', ['rebates', 'zeroPercentFinancing']),
    isBookmarked() {
      return this.isProductInBookmarks(this.offer?.product?.articleNumber);
    },
    availability() {
      return availabilityCalculator(
        this.offer.product?.stock?.amount,
        this.offer.product?.alwaysDisplayed
      );
    },
    title() {
      if (this.offer.contract) return this.offer.contract.name;
      return this.offer.product.family.name;
    },
    isBundle() {
      return !!(this.offer.product && this.offer.product.bundle);
    },
    rebateDescription() {
      return this.rebates[this.offer.product.articleNumber]?.description;
    },
    deliveryLeadTime() {
      if (this.offer.contract) return '';
      return this.offer.product.deliveryLeadTime;
    },
    imageSources() {
      return this.offer.teaserImage?.sources;
    },
    brand() {
      return getBrand(this.offer);
    },
    inStock() {
      const { product } = this.offer;
      // An offer must always contain at least a product or contract (or both).
      // When no product is present in the offer we can be certain to have a
      // contract. As contracts are intangible they may not have stock and are
      // considered always available.
      if (!product) return true;
      return product.stock.amount > 0;
    },
    providerName() {
      const { product } = this.offer;
      if (this.offer.contract) return PROVIDER_NAMES[this.offer.contract.provider];
      return PROVIDER_NAMES[product.stock.provider];
    },
    price() {
      return getPrice(this.offer.prices, 'DEFAULT', 'ONETIME')?.value;
    },
    wasPrice() {
      return getPrice(this.offer.prices, 'WAS', 'ONETIME')?.value;
    },
    hasHighlightFlag() {
      return this.articleNumbersWithAlternatives.some((articleNumber) =>
        HIGHLIGHTS_OFFER.includes(articleNumber)
      );
    },
    articleNumbersWithAlternatives() {
      const articleNumbers = [this.offer?.product?.articleNumber];
      const properties = this.offer.productAlternatives?.[0]?.properties || [];

      properties.forEach(({ alternatives }) => {
        articleNumbers.push(
          ...alternatives
            .map(({ offer }) => offer?.product?.articleNumber)
            .filter((articleNumber) => articleNumber && !articleNumbers.includes(articleNumber))
        );
      });

      return articleNumbers.filter(Boolean);
    },
    discount() {
      return getDiscount(this.price, this.wasPrice);
    },
    telekomContractLink() {
      return this.offer.product?.stock?.provider === 'TELEKOM'
        ? getTelekomContractLink(this.offer)
        : '';
    },
    contractLinkText() {
      return getTelekomContractLinkText(this.$route.path);
    },
    isCouponCompatible() {
      if (this.compatibleArticleNumbers?.length > 0) {
        return this.compatibleArticleNumbers.includes(this.offer.product.articleNumber);
      }
      if (this.compatibleProductProviders?.length > 0) {
        return this.compatibleProductProviders.includes(this.offer.product.origin);
      }
      if (this.incompatibleArticleNumbers?.length > 0) {
        return !this.incompatibleArticleNumbers.includes(this.offer.product.articleNumber);
      }
      return true;
    },
    hasZeroPercentFinancing() {
      return this.zeroPercentFinancing.includes(this.offer.product.articleNumber);
    },
    hasTreueshopFlag() {
      return (
        this.isValidCoupon &&
        this.couponInfo.type === 'coupon' &&
        this.reductionValue &&
        this.couponInfo.typeCode === '02' &&
        this.isCouponCompatible &&
        !this.offer.prices.some((price) => price.type.toLowerCase() === 'was')
      );
    },
    treueShopFlagText() {
      if (this.couponInfo.reductionMethodCode === 'RP') return `-${this.reductionValue}% Rabatt`;
      if (['RS', 'RT'].includes(this.couponInfo.reductionMethodCode))
        return `abzüglich ${this.reductionValue} €`;
      return '';
    },
    leftFlagColor() {
      if (this.offer.isNew || !!this.discount || this.rebateDescription || !this.hasHighlightFlag)
        return '53BAF2';
      return 'e20074';
    },
    logoWidthAttribute() {
      if (this.brand.logoMeasurements?.width?.offerTile) {
        return { width: this.brand.logoMeasurements?.width?.offerTile || 'auto' };
      }
      return {};
    },
  },
  methods: {
    toggleBookmarkNumber() {
      const state = this.toggleBookmark(
        this.offer?.product?.articleNumber,
        this.offer?.product?.name,
        this.price
      );
    },
    clickContractLink() {
      Tealium.link({
        wt_link_id: `content.button.zu-den-details.${this.offer?.product?.articleNumber}`,
      });
      window.open(this.telekomContractLink, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.offer-tile {
  --max-list-width: 90vw;

  &__bookmark,
  &__add-to-cart {
    position: relative;
    bottom: 1rem;
    margin-top: -1rem;
  }

  &__bookmark {
    right: $grid-base * -1;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 16px;

    @include mq($mq-xl) {
      right: $grid-base * -2;
    }

    &-icon {
      fill: none;
      stroke: currentColor;
      stroke-width: 1px;
      width: 24px;
    }

    &--active {
      color: $color-primary;
      border-color: $color-primary;

      .offer-tile__bookmark-icon {
        fill: currentColor;
      }
    }
  }

  &__zero-percent-financing {
    appearance: none;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    color: white;
    font-weight: 700;
    cursor: pointer;
    @include font-size(14px, 14px);

    sup {
      @include font-size(18px, 0px);
      line-height: 0;
      vertical-align: baseline;
    }

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: white;
    }

    &-text {
      text-align: center;
      padding: 1rem;
    }
  }

  &__delivery,
  &__unavailable {
    flex: 1;
  }

  &__unavailable {
    color: $color-error;
  }

  &__badge {
    --badge-xs-size: 15px;
    --badge-xs-padding: 0.4em;
    --badge-xs-top: 0;
    --badge-xs-left: 60%;
    --badge-sm-size: 15px;
    --badge-sm-padding: 0.4em;
    --badge-sm-left: 0;

    :deep(.badge__content) {
      min-width: 80px;
    }
  }

  &__contract-link {
    text-align: left;
    @include font-size(16px, 18px);
    white-space: normal;
    color: color(blue, 1500);
  }

  &__logo-image {
    height: 20px;
    align-self: flex-start;
  }

  &--treueShop {
    :deep(.offer-tile__highlight-flag) {
      background-color: color(blue, 5000);
    }
  }

  :deep(.offer-tile__info) {
    justify-content: space-between;
  }

  &--discounted {
    :deep(.offer-tile__rectangular-badge) {
      --badge-font-size: 21px;
    }
  }

  &__rectangular-badge,
  &__highlight-flag {
    @include mq($mq-sm, $mq-lg - 1) {
      --flag-padding: 12px;
    }
  }
}
</style>
