const { mode } = require('@/lib/public-runtime-config');

const SavedFeatures = require('@i22/status-toolbar/dist/saved-features');

const onlyDevelopment = mode.isDevelopment;
const onlyDevelopmentAndPreview = !(mode.isProduction || mode.isStaging);

export type Feature = {
  enabled: boolean;
};

const features: Record<string, Feature> = {
  handyVergleich: {
    // https://jira.i22.de/browse/EGS-1612
    enabled: onlyDevelopmentAndPreview,
  },
  simulateBeQueue: {
    enabled: false,
  },
  dcbMailSent: {
    // https://i22-telekom-longtail.atlassian.net/browse/EGS-2362
    enabled: false,
  },
  dcbPromotion: {
    enabled: true,
  },
};

export type Features = Record<string, Feature> & {
  defaultFeatures: Record<string, Feature>;
};

export default SavedFeatures.getFeatures(features, mode.isProduction) as Features;
