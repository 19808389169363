import { ref } from 'vue';
/**
 * Note: This has to be placed in the client!
 * use in "mounted"-hook or "onMounted" for composition api.
 * OR
 * use if (process.client).
 * OR
 * <client-only> component in nuxt
 *
 * Make sure your code is reactive!
 */
export type Device = 'Android' | 'iOS' | 'Desktop' | undefined;

export const device = ref<Device>(undefined);
export const isMobile = ref<boolean>(false);

const getDevice = (): Device => {
  const { userAgent, platform, maxTouchPoints } = navigator || {};

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) || (/macintel/i.test(platform) && maxTouchPoints > 0)) {
    return 'iOS';
  }

  if (
    /(Windows|Win32|WinCE|Macintosh|MacPPC|Mac68k)/i.test(userAgent) ||
    (/macintel/i.test(platform) && (!maxTouchPoints || maxTouchPoints === 0))
  ) {
    return 'Desktop';
  }

  return undefined;
};

if (process.client) {
  device.value = getDevice();
  isMobile.value = ['Android', 'iOS'].includes(device.value || '');
}

export default getDevice;
