<template>
  <!-- Expandable -->
  <div
    v-if="expandable"
    class="main-navigation-item main-navigation-item--expandable"
    :class="classes"
    @mouseover="mouseOver"
    @mouseleave="menuOpened = false"
  >
    <LinkButton
      ref="linkButton"
      :class="linkClass"
      :data-tealium-rel="trackingKey"
      :href="linkHref"
      :tag="linkTag"
      :target="linkTarget"
      :title="label"
      :to="linkTo"
      class="main-navigation-item__link"
      no-styling
    >
      {{ label }}
    </LinkButton>

    <div
      class="main-navigation-item__icon-button"
      @click.prevent.stop="toggle()"
    >
      <SvgIcon
        :image="arrowLeftMenu"
        class="main-navigation-item__icon main-navigation-item__icon--right"
        no-icon-class
      />
    </div>

    <div class="main-navigation-item__subnav">
      <LinkButton
        class="main-navigation-item main-navigation-item__link main-navigation-item__link--big main-navigation-item__link--active main-navigation-item__subnav-back"
        no-styling
        tag="button"
        @click.native.prevent="toggle()"
      >
        <SvgIcon
          :image="arrowLeftMenu"
          class="main-navigation-item__icon main-navigation-item__icon--left"
        />
        Zurück
      </LinkButton>
      <div class="main-navigation-item__subnav-container">
        <div
          :class="modifierClass"
          class="main-navigation-item__subnav-wrapper"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>

  <LinkButton
    v-else
    class="main-navigation-item main-navigation-item__link"
    :class="linkClass"
    :data-tealium-rel="trackingKey"
    :href="linkHref || linkTo"
    :rel="linkRel"
    :tag="linkTag"
    :target="linkTarget"
    :title="label"
    :to="linkTo"
    exact
    no-styling
    @click.native="click"
  >
    {{ label }}
  </LinkButton>
</template>

<script>
import { isUndefined, startsWith } from 'lodash';
import Escapable from '@/mixins/escapable';
import dasherize from '@/lib/dasherize';
import { mapGetters } from 'vuex';

import { LinkButton } from '@i22-td-smarthome/component-library';

import arrowLeftMenu from '@/assets/images/arrow-left-menu.svg';

const EXTERNAL_URL_PREFIXES = ['http://', 'https://', '//'];

export const popupSwitchDate = new Date(2024, 7, 26).getTime();

export default {
  name: 'MainNavigationItem',
  mixins: [Escapable],
  components: {
    LinkButton,
  },
  props: {
    singleLevelNavigation: { type: Boolean },
    label: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: '',
    },
    exact: {
      type: Boolean,
      default: null,
    },
    external: {
      type: Boolean,
      default: undefined,
      required: false,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    additionalClass: {
      type: String,
      default: '',
      required: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 1,
    },
    additionalLinkClass: { type: String, default: null },
  },
  data() {
    return {
      arrowLeftMenu,
      menuOpened: false, // desktop
      expanded: false, // mobile
    };
  },
  emits: ['subnav-open'],
  computed: {
    ...mapGetters('backdrop', { hasActiveBackdrop: 'isActive' }),
    classes() {
      const classes = [];
      if (this.expanded) {
        classes.push('main-navigation-item--expanded');
      }
      if (this.menuOpened) {
        classes.push('main-navigation-item--hovered');
      }
      classes.push(`main-navigation-item--${dasherize(this.label)}`);
      if (this.hasActiveBackdrop) {
        const isPopupOpen = localStorage.getItem('POPUP') === 'open';
        if (
          isPopupOpen &&
          new Date().getTime() > popupSwitchDate &&
          this.linkTo === '/smart-home'
        ) {
          classes.push(`main-navigation-item--animated`);
        }
      }
      return classes;
    },
    linkClass() {
      const classes = [];
      if (this.level === 1) {
        classes.push('main-navigation-item__link--big');
      }
      if (this.active) {
        classes.push('main-navigation-item__link--active');
      }
      if (this.additionalLinkClass) {
        classes.push(this.additionalLinkClass);
      }
      return classes;
    },
    trackingKey() {
      return `navi-top.text.${dasherize(this.label)}`;
    },
    isExternal() {
      if (!isUndefined(this.external)) return this.external;
      if (!this.to) return false;
      return EXTERNAL_URL_PREFIXES.some((prefix) => startsWith(this.to, prefix));
    },
    targetRoute() {
      if (this.isExternal || !this.$router || !this.$route || !this.to) return undefined;
      const result = this.$router.resolve(this.to);
      if (!result) return undefined;
      return result.route;
    },
    isActive() {
      if (!isUndefined(this.active)) return this.active;
      if (!this.targetRoute) return false;
      if (this.exact) {
        return this.$route.fullPath === this.targetRoute.fullPath;
      }
      return startsWith(this.$route.path, this.targetRoute.path);
    },
    modifierClass() {
      const classes = [];
      if (this.additionalClass) classes.push(this.additionalClass);
      if (this.singleLevelNavigation) {
        classes.push('main-navigation-item__subnav-wrapper--single-level');
      }
      return classes;
    },
    linkHref() {
      return this.linkTag === 'a' ? this.to : '';
    },
    linkTo() {
      return this.linkTag === 'nuxt-link' ? this.to : '';
    },
    linkTarget() {
      return this.external ? '_blank' : '_self';
    },
    linkTag() {
      // empty "to" will add "nuxt-link-active"-class
      if (!this.to) return 'a';
      return this.external ? 'a' : 'nuxt-link';
    },
    linkRel() {
      return this.external ? 'noopener' : '';
    },
    isLargeTouchscreen() {
      return this.expandable && this.$screenSize.isAtLeastMediumDesktopScreen.value;
    },
  },
  watch: {
    $route() {
      this.expanded = false; // close submenu on route change
      this.menuOpened = false;
    },
    expanded() {
      this.$emit('subnav-open', this.expanded ? this.level : this.level - 1);
    },
  },
  methods: {
    toggle() {
      if (!this.expandable) return;
      this.expanded = !this.expanded;
    },
    click() {
      // call to close all open menus
      this.$nuxt.$emit('close-menu');
      document.querySelector('body')?.classList?.remove('mobile-nav-open');
    },
    mouseOver(e) {
      const isTouch = e?.sourceCapabilities?.firesTouchEvents || false;
      if (!isTouch) {
        this.menuOpened = true; // do not open flyout on touch-device.
      }
    },
  },
  mounted() {
    // the event from `nuxt-link` cannot be stopped by using "@click.prevent". So we have to do it so.

    if (this.$refs.linkButton?.$el) {
      this.$refs.linkButton.$el.addEventListener(
        'click',
        (e) => {
          const isTouch = e.pointerType === 'touch';
          if (isTouch && this.isLargeTouchscreen) {
            // first touch: open flyout.
            if (!this.menuOpened) {
              this.menuOpened = true;
              e.preventDefault();
              return;
            }
          }
          // close mobile menu immediately to show loading spinner.
          this.click();
          this.menuOpened = false;
        },
        true
      );
    }

    this.$nuxt.$on('close-menu', () => {
      this.menuOpened = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.main-navigation-item {
  padding-inline: $grid-base * 3;

  @include mq(false, $mq-lg - 1) {
    border-bottom: 1px solid color(grey, 29000);
  }
  @include mq($mq-lg) {
    padding-inline: $grid-base;
  }

  @include mq($mq-lg) {
    padding-inline: $grid-base * 1.5;
  }

  &__link {
    white-space: normal;
    text-transform: none;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    background: none;
    color: $color-nav;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq(false, $mq-lg - 1) {
      width: 100%;
      min-height: 60px;
    }

    @include mq($mq-lg) {
      display: block;
    }

    &:hover,
    &.nuxt-link-exact-active,
    &--active {
      color: $color-primary;
      text-decoration: none;
    }

    &:not(&--big) {
      @include font-size(20px, 20px);
      padding-block: 6px;

      @include mq($mq-lg) {
        @include font-size(16px, 22px);
        padding: 4px 0;
        margin-bottom: 8px;
      }
    }

    &--big {
      font-weight: 800;

      @include mq(false, $mq-lg - 1) {
        @include font-size(24px, 24px);
        min-height: 80px;
      }

      @include mq($mq-lg) {
        display: flex;
        height: 70px;
        position: relative;
      }

      &.nuxt-link-active {
        color: $color-primary;
      }
    }
  }

  &--expandable {
    @include mq(false, $mq-lg - 1) {
      display: flex;

      > .main-navigation-item__link {
        width: auto;
        flex: 0 1 auto;
      }

      > .main-navigation-item__icon-button {
        flex: 1 1 0;
      }
    }
  }

  &--expanded {
    @include mq(false, $mq-lg - 1) {
      // This ensures, the Menu is closed, when a link is clicked
      > .main-navigation-item__subnav {
        display: block;
      }
    }
  }

  &:focus,
  &:hover {
    @include mq($mq-lg) {
      &.main-navigation-item__link--big,
      > .main-navigation-item__link--big {
        color: $color-primary;
        fill: $color-primary;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4px;
          background-color: color(magenta, 1000);
        }
      }

      &.main-navigation-item__link--big {
        &::after {
          left: $grid-base;
          right: $grid-base;

          @include mq($mq-lg) {
            left: $grid-base * 1.5;
            right: $grid-base * 1.5;
          }
        }
      }
    }
  }

  &--hovered {
    @include mq($mq-lg) {
      > .main-navigation-item__subnav {
        visibility: visible;
      }
    }
  }

  &__subnav {
    position: absolute;
    top: 0;
    width: 100%;

    @include mq(false, $mq-lg - 1) {
      left: 100%;
      display: none;
    }

    @include mq($mq-lg) {
      visibility: hidden;
      @include font-size(16px, 22px);
      top: 100%;
      left: 0;
      height: calc(100vh - 96px);
      pointer-events: none;
      background: rgba(0, 0, 0, 0.6);

      // This overlay prevents to hover the whitespace from other nav items.
      &:before {
        $navItemSpace: 26px; // navItemHeight - Line-Height (real height)
        content: '';
        left: 0;
        right: 0;
        top: $navItemSpace * -1;
        height: $navItemSpace;
        z-index: 2;
        position: absolute;
        pointer-events: all; // catch mouse
      }
    }
  }

  &__subnav-back {
    justify-content: flex-start;

    @include mq($mq-lg) {
      display: none;
    }
  }

  &__subnav-container {
    @include mq($mq-lg) {
      background: color(white);
      pointer-events: all;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      max-height: 100%;
      box-shadow: inset 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    }
  }

  &__subnav-wrapper {
    @include mq($mq-lg) {
      width: 100%;
      position: relative;
      max-width: $mq-lpg-lg-width + $grid-base * 4;
      margin: 0 auto;
      padding: $grid-base * 2;
      min-height: 250px;
      grid-gap: $grid-base * 2 $grid-base * 8;

      &:not(&--single-level) {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &__icon {
    width: $grid-base * 2;
    height: $grid-base * 2;
    margin-right: $grid-base * 0.5;
    @include mq($mq-lg) {
      width: $grid-base * 1.5;
      height: $grid-base * 1.5;
      margin-left: $grid-base * 0.5;
      margin-right: 0;
    }

    &--left {
      margin-left: $grid-base * -1;
    }

    &--right {
      transform: rotateZ(180deg);
    }

    &-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include mq($mq-lg) {
        display: none;
      }
    }
  }

  &--animated {
    padding-inline: 0;
    & > a {
      border: 4px solid transparent;
      border-radius: 9px;
      animation: border-animation 5s linear infinite;
      padding-inline: 1.5 * $grid-base;

      &::after {
        display: none;
      }
    }
  }

  @keyframes border-animation {
    0% {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    20% {
      border-left-color: $color-primary;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
    40% {
      border-left-color: $color-primary;
      border-top-color: $color-primary;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
    60% {
      border-left-color: $color-primary;
      border-top-color: $color-primary;
      border-right-color: $color-primary;
      border-bottom-color: transparent;
    }
    80% {
      border-left-color: $color-primary;
      border-top-color: $color-primary;
      border-right-color: $color-primary;
      border-bottom-color: $color-primary;
      color: $color-font;
    }
    100% {
      border-left-color: $color-primary;
      border-top-color: $color-primary;
      border-right-color: $color-primary;
      border-bottom-color: $color-primary;
      color: $color-primary;
    }
  }
}

body.nav-scrolled {
  .main-navigation-item__subnav {
    @include mq($mq-lg) {
      height: calc(100vh - 70px);
    }
  }
}
</style>
