<template>
  <GridContainer class="footer-brand">
    <GridRow>
      <GridColumn class="footer-brand__content">
        <SvgIcon
          :image="IconLogo"
          class="footer-brand__logo"
        />
      </GridColumn>
    </GridRow>
  </GridContainer>
</template>
<script>
import { GridColumn, GridContainer, GridRow } from '@i22-td-smarthome/component-library';

// Icons
import IconLogo from '@/assets/images/logo.svg';

export default {
  name: 'FooterBrand',
  components: {
    GridRow,
    GridContainer,
    GridColumn,
  },
  data() {
    return {
      IconLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.footer-brand {
  padding: $grid-base * 2 $grid-base $grid-base * 3;
  max-width: $max-navbar-width + $grid-base * 4;

  @include mq($mq-sm) {
    padding: 2 * $grid-base $grid-base;
  }

  @include mq($mq-md) {
    padding: $grid-base * 2;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0;
  }

  &__logo {
    fill: color(white);
    width: 45px;
    height: 38px;
  }
}
</style>
