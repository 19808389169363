<template>
  <GridColumn
    :xs="12"
    :md="4"
    class="footer-column"
  >
    <Heading
      tag="strong"
      class="footer-column__headline pb--1"
      :data-tealium-rel="`footer.column.${headline}` | dasherize"
    >
      {{ headline }}
    </Heading>
    <div class="footer-column__content">
      <slot></slot>
    </div>
  </GridColumn>
</template>

<script>
import { Heading, GridColumn } from '@i22-td-smarthome/component-library';

import Inflections from '@/mixins/inflections';

export default {
  name: 'FooterColumn',
  components: {
    Heading,
    GridColumn,
  },
  mixins: [Inflections],
  props: {
    headline: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.footer-column {
  color: $color-secondary;

  &__headline {
    position: relative;
    margin-bottom: $grid-small-gutter;
    color: $color-secondary;
    @include font-size(16px, 32px);
    font-weight: 700;
    text-transform: uppercase;
    @include mq($mq-medium) {
      @include font-size(16px, 18px);
    }
  }

  &__content {
    margin-bottom: $grid-small-gutter;
  }
}
</style>
