<template>
  <span
    v-if="hasMultipleVariants"
    class="alternative-offers-count"
  >
    + {{ additionalCount }} weitere {{ pluralOrSingularNoun }}
  </span>
</template>

<script>
export default {
  name: 'AlternativeOffersCount',
  props: {
    productAlternatives: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    pluralOrSingularNoun() {
      return this.additionalCount === 1 ? 'Variante' : 'Varianten';
    },
    hasMultipleVariants() {
      return this.additionalCount > 0;
    },
    additionalCount() {
      const alternativeCount = this.productAlternatives
        .flatMap((productAlternative) => productAlternative.properties)
        .map((property) => property.alternatives.length)
        .reduce((a, b) => a * b, 1);

      const activeItemCount = 1;

      return alternativeCount - activeItemCount;
    },
  },
};
</script>

<style lang="scss">
.alternative-offers-count {
  color: #757575;
  font-size: calc(16 / 18) em;
}
</style>
