import { render, staticRenderFns } from "./cdn-image.vue?vue&type=template&id=e72d5258"
import script from "./cdn-image.vue?vue&type=script&lang=js"
export * from "./cdn-image.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports