<template>
  <ImageElement
    :id="id"
    :src="finalSrc.xs"
    :src-sm="finalSrc.sm"
    :src-md="finalSrc.md"
    :src-lg="finalSrc.lg"
    :src-xl="finalSrc.xl"
    :image-class="imageClass"
    :image-style="imageStyle"
    :picture-class="pictureClass"
    :alt="alt"
    :height="height"
    :width="width"
    :render-without-figure="renderWithoutFigure"
    :align="align"
    :spaces="spaces"
    :lazy="lazy"
    :srcset="imageSourceset"
    :sizes="imageSizes"
  >
    <slot />
  </ImageElement>
</template>

<script>
import { ImageElement } from '@i22-td-smarthome/component-library';
import NuxtPicture from '@nuxt/image/dist/runtime/components/nuxt-picture.vue';

export default {
  name: 'CdnImage',
  components: {
    ImageElement,
  },
  mixins: NuxtPicture.mixins,
  head() {
    if (this.preload === true) {
      const srcKey = typeof this.nSources[1] !== 'undefined' ? 1 : 0;
      const link = {
        rel: 'preload',
        as: 'image',
        imagesrcset: this.nSources[srcKey].srcset,
      };
      if (typeof this.nSources[srcKey].sizes !== 'undefined') {
        link.imagesizes = this.nSources[srcKey].sizes;
      }
      return {
        link: [link],
      };
    }
    return {};
  },
  props: {
    ...NuxtPicture.props,
    sizes: {
      type: String,
      default: '', // "sm:100vw md:50vw lg:400px" https://v0.image.nuxtjs.org/components/nuxt-img#sizes
    },
    src: { type: String, required: true },
    srcSm: { type: String, default: null },
    srcMd: { type: String, default: null },
    srcLg: { type: String, default: null },
    srcXl: { type: String, default: null },
    imageClass: { type: String, default: '' },
    imageStyle: { type: String, default: '' },
    pictureClass: { type: String, default: '' },
    alt: { type: String, required: true },
    id: { type: String, default: null },
    height: { type: Number, default: null },
    width: { type: Number, default: null },
    renderWithoutFigure: { type: Boolean },
    doNotConvert: { type: Boolean },
    align: {
      type: String,
      default: null,
      validator(value) {
        return ['center', 'right', 'left'].includes(value);
      },
    },
    spaces: { type: Boolean },
    lazy: { type: Boolean },
  },
  computed: {
    ...NuxtPicture.computed,

    // { xs: '100vw', sm: '100px' }..
    requestedSizes() {
      const sizes = {};
      if (typeof this.sizes === 'string') {
        this.sizes
          .split(/[\s,]+/)
          .filter((e) => e)
          .forEach((entry) => {
            const s = entry.split(':');
            if (s.length !== 2) {
              return;
            }
            sizes[s[0].trim()] = s[1].trim();
          });
      } else {
        Object.assign(sizes, this.sizes);
      }
      return sizes;
    },
    imagesBreakpoints() {
      return [
        {
          src: this.src,
          size: 'xs',
          width: this.requestedSizes.xs,
          defaultWidth: this.$img.options.screens.xs,
        },
        {
          src: this.srcSm,
          size: 'sm',
          width: this.requestedSizes.sm,
          defaultWidth: this.$img.options.screens.sm,
        },
        {
          src: this.srcMd,
          size: 'md',
          width: this.requestedSizes.md,
          defaultWidth: this.$img.options.screens.md,
        },
        {
          src: this.srcLg,
          size: 'lg',
          width: this.requestedSizes.lg,
          defaultWidth: this.$img.options.screens.lg,
        },
        {
          src: this.srcXl,
          size: 'xl',
          width: this.requestedSizes.xl,
          defaultWidth: this.$img.options.screens.xl,
        },
      ];
    },
    nLegacyFormat() {
      if (this.legacyFormat) {
        return this.legacyFormat;
      }
      return 'webp';
    },
    finalSrc() {
      const [xs, sm, md, lg, xl] = this.imagesBreakpoints.map(
        ({ src, width, size, defaultWidth }) => {
          if (!src && !width) return null;
          if (this.nFormat === 'svg') {
            return [
              {
                src: this.src,
                type: `image/svg`,
              },
            ];
          }
          // we have a size and no image: Search the "last" image before this breakpoint
          if (!src) {
            // eslint-disable-next-line no-param-reassign
            src = this.getLastSource(size);
            if (!src) return null;
          }

          const modifiers = {
            format: this.nFormat,
          };
          if (width) {
            modifiers.width = width;
          }

          const options = {
            sizes: `${size}:${width || defaultWidth}`,
            modifiers: {
              format: this.nFormat,
            },
          };
          const viewPortImage = this.doNotConvert ? { src } : this.$img.getSizes(src, options);

          return [
            {
              size,
              width: width || defaultWidth,
              src: viewPortImage.src,
              type: `image/${this.nFormat}`,
            },
          ];
        }
      );
      return { xs, sm, md, lg, xl };
    },
    imageSourceset() {
      return this.renderWithoutFigure && this.sizes && this.nSources[0].srcset?.length > 1
        ? this.nSources[0].srcset
        : undefined;
    },
    imageSizes() {
      return this.renderWithoutFigure && this.sizes && this.nSources[0].sizes?.length > 1
        ? this.nSources[0].sizes
        : undefined;
    },
  },
  methods: {
    getLastSource(size) {
      const index = this.imagesBreakpoints.findIndex((e) => e.size === size);
      for (let i = index; i >= 0; i -= 1) {
        if (this.imagesBreakpoints[i].src) return this.imagesBreakpoints[i].src;
      }
      return undefined;
    },
  },
};
</script>
