<template>
  <div class="prices-container">
    <Prices
      v-if="price"
      :is-price="price"
      :was-price="wasPrice"
      :pre-text-is-price="preTextIsPrice"
      :pre-text-was-price="preTextWasPrice"
      :post-text-is-price="priceMonthly && !wasPrice ? 'einmalig' : ''"
      :post-text-was-price="priceMonthly && wasPrice ? 'einmalig' : ''"
      :variant="variant"
      :theme="theme"
    >
      <template
        v-if="hasFootnote"
        #footnote
      >
        <LegalModalButton :texts="legalTexts" />
      </template>
    </Prices>
    <Prices
      v-if="priceMonthly"
      :is-price="priceMonthly"
      :was-price="wasPriceMonthly"
      pre-text-is-price=""
      pre-text-was-price=""
      :post-text-is-price="wasPriceMonthly ? '' : 'monatlich'"
      :post-text-was-price="wasPriceMonthly ? 'monatlich' : ''"
      :variant="variant"
      :theme="theme"
    >
      <template
        v-if="hasFootnote && !price"
        #footnote
      >
        <LegalModalButton :texts="legalTexts" />
      </template>
    </Prices>
  </div>
</template>

<script>
import { getPriceFormatted } from '@/lib/price-from-offer';
import { Prices } from '@i22-td-smarthome/component-library';
import LegalModalButton from '@/components/legal-modal-button.vue';

export default {
  name: 'PricesContainer',
  components: {
    Prices,
    LegalModalButton,
  },
  props: {
    pricesData: {
      type: Array,
      required: true,
    },
    legalTexts: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'large',
    },
    preTextIsPrice: {
      type: String,
      default: '',
    },
    preTextWasPrice: {
      type: String,
      default: 'statt',
    },
    theme: {
      type: String,
      default: 'dark',
      validator(value) {
        return ['light', 'dark'].includes(value);
      },
    },
  },
  computed: {
    price() {
      return getPriceFormatted(this.pricesData, 'DEFAULT', 'ONETIME');
    },
    wasPrice() {
      return getPriceFormatted(this.pricesData, 'WAS', 'ONETIME');
    },
    priceMonthly() {
      return getPriceFormatted(this.pricesData, 'DEFAULT', 'MONTHLY');
    },
    wasPriceMonthly() {
      return getPriceFormatted(this.pricesData, 'WAS', 'MONTHLY');
    },
    hasFootnote() {
      return this.legalTexts.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.prices-container {
  padding-bottom: 0;
  :deep(.legal-modal-button) {
    font-size: 1.5em;
    top: 0;
    align-self: flex-start;
    margin-left: 0;
    margin-right: 10px;
  }
}
</style>
