import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import ShopApi from '@/lib/shop-api';
import type { RootState } from '@/types/store-types';

type Downtime = {
  startDate: Date;
  endDate: Date;
  systems: string[];
  maintenanceHeading?: string;
  maintenanceDescription?: string;
  description?: string;
};

// const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000; // updateInterval

function downtimeNow(downtime: Downtime) {
  const convertedStartDate = new Date(downtime.startDate);
  const convertedEndDate = new Date(downtime.endDate);
  const timestamp = new Date(downtime.startDate).getTime() / 1000;
  const isDate = timestamp && timestamp > 0 && !Number.isNaN(downtime.startDate);
  if (!isDate) return undefined;

  const dateNow = new Date(Date.now());

  return convertedStartDate < dateNow && convertedEndDate > dateNow;
}

export interface DowntimesState {
  downtimes: Downtime[];
  lastUpdated: number;
}

export const state = (): DowntimesState => ({
  downtimes: [],
  lastUpdated: 0,
});

export const mutations: MutationTree<DowntimesState> = {
  SET_DOWNTIMES(state, downtimes) {
    state.downtimes = downtimes;
  },
  SET_LAST_UPDATED(state, timestamp) {
    state.lastUpdated = timestamp;
  },
};

export const actions: ActionTree<DowntimesState, RootState> = {
  async fetchDowntimes({ commit, getters }) {
    if (!getters.downtimesIntervalTimeUp) return;
    try {
      const data = await ShopApi.downtimes();
      commit('SET_DOWNTIMES', data?.downtimes || []);
    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_LAST_UPDATED', Date.now());
    }
  },
};

export const getters: GetterTree<DowntimesState, RootState> = {
  downtimes(state) {
    return state.downtimes;
  },
  downtimesIntervalTimeUp(state) {
    return state.lastUpdated + TEN_MINUTES_IN_MILLISECONDS < Date.now();
  },
  tvppDowntime(state) {
    return state.downtimes.find((dt) => dt.systems.find((s) => s === 'tvpp') && downtimeNow(dt));
  },
  qiviconDowntime(state) {
    return state.downtimes.find((dt) => dt.systems.find((s) => s === 'qivicon') && downtimeNow(dt));
  },
  maintenanceDowntime(state) {
    return state.downtimes.find(
      (dt) => dt.systems.find((s) => s === 'maintenance') && downtimeNow(dt)
    );
  },
};
