<template>
  <BaseLayout
    theme-header="small"
    theme-footer="small"
    :show-content="cartInitialized"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Layout from '@/mixins/layout';
import BaseLayout from '@/components/base-layout.vue';

export default {
  name: 'CheckoutLayout',
  components: {
    BaseLayout,
  },
  mixins: [Layout],
  computed: {
    ...mapGetters('cart', ['cartIsEmpty', 'cartInitialized']),
    ...mapGetters('downtimes', ['maintenanceDowntime']),
    isCartEmpty() {
      return this.cartIsEmpty && this.cartInitialized;
    },
  },
  methods: {
    ...mapActions('downtimes', ['fetchDowntimes']),
  },
  watch: {
    isCartEmpty: {
      handler(value) {
        if (value) {
          this.$router.replace('/shop/warenkorb');
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    try {
      await this.fetchDowntimes();
      if (this.maintenanceDowntime) {
        await this.$router.push('/maintenance');
      }
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
