import baseGoliathRequest from '@/lib/goliath/base-goliath-request';
import type {
  OfferCategoryBySlugsQuery,
  OfferCategoryBySlugsQueryVariables,
  ProductCategory,
} from '@/lib/goliath/goliath.types';

export default async (
  params: OfferCategoryBySlugsQueryVariables,
  progress = true
): Promise<ProductCategory | undefined> => {
  const response: OfferCategoryBySlugsQuery['offerBySlugs'] = await baseGoliathRequest(
    '/api/goliath/offer-category-by-slugs',
    params,
    undefined,
    progress
  );
  return response?.product?.family?.primaryCategory || undefined;
};
