<template>
  <div class="primary-navigation">
    <div class="primary-navigation__tabs">
      <span class="primary-navigation__tab-entry primary-navigation__tab-entry--active"
        >Privatkunden</span
      >
      <a
        class="primary-navigation__tab-entry"
        href="https://geschaeftskunden.telekom.de/"
        rel="noopener"
        target="_blank"
      >
        Geschäftskunden
      </a>
    </div>
    <div
      class="primary-navigation__navigation-items-wrapper"
      :class="wrapperClass"
    >
      <NavigationItem
        v-for="navigationItem in items"
        :key="navigationItem.path"
        :navigation-item="navigationItem"
        @subnav-open="handleSubmenuToggle"
      />
    </div>
  </div>
</template>

<script>
import NavigationItem from '@/components/navigation/navigation-item.vue';

export default {
  name: 'PrimaryNavigation',
  components: {
    NavigationItem,
  },
  data() {
    return {
      showSubNav: 0,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    wrapperClass() {
      if (this.showSubNav === 0) return '';
      return `primary-navigation__navigation-items-wrapper--open-${this.showSubNav}`;
    },
  },
  methods: {
    handleSubmenuToggle(value) {
      this.showSubNav = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.primary-navigation {
  top: 100%;
  left: 0;
  right: 0;

  @include mq(false, $mq-lg - 1) {
    position: absolute;
    background: color(white);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: $grid-base * 4;
    height: calc(100vh - 60px);
  }

  @include mq($mq-lg) {
    display: inline-flex;
  }

  &__tabs {
    background: color(white);
    padding-top: $grid-base * 2;
    display: flex;
    @include mq($mq-lg) {
      display: none;
    }
  }

  &__tab-entry {
    flex: 1 1 50%;
    @include font-size(18px, 21px);
    font-weight: 800;
    display: block;
    text-align: center;
    height: 40px;
    padding: 0 6px;
    border-bottom: 1px solid #ededed;
    color: $color-nav;

    &--active {
      border-color: $color-primary;
      color: $color-primary;
    }
  }

  &__navigation-items-wrapper {
    @include mq(false, $mq-lg - 1) {
      margin-bottom: env(safe-area-inset-bottom);
      transition: transform 0.4s ease-out;

      &--open-1 {
        transform: translateX(-100%);
      }
      &--open-2 {
        transform: translateX(-200%);
      }
    }

    @include mq($mq-lg) {
      display: flex;
      align-items: center;
    }
  }
}
</style>
