// When the context is a Browser, use the client sentry, in node use the node version.
// the configuration is used from `nuxt.config.js`
import type { CaptureContext } from '@sentry/types';

const getSentry = () => {
  if (process.sentry) {
    return process.sentry;
  }
  if (process.client) {
    return window.$nuxt.$sentry;
  }
  // this should not happen.
  return {
    captureMessage: (e: string) => {
      // eslint-disable-next-line no-console
      console.log(e);
    },
    captureException: (e: Error | unknown) => {
      console.error(e);
    },
  };
};

const captureException = (error: Error | unknown, captureContext?: CaptureContext) => {
  getSentry().captureException(error, captureContext);
};
const captureMessage = (error: string, captureContext?: CaptureContext) => {
  getSentry().captureMessage(error, captureContext);
};

export { captureException, captureMessage, getSentry };
