<template>
  <div class="layout layout--onlybranding">
    <Branding
      ref="branding"
      class="layout__branding"
    />
    <nuxt />
    <portal-target name="globals" />
  </div>
</template>

<script>
import Layout from '@/mixins/layout';
import Branding from '@/components/branding.vue';

export default {
  name: 'OnlyBrandingLayout',
  components: {
    Branding,
  },
  mixins: [Layout],
};
</script>

<style lang="scss">
.layout--onlybranding {
  background-color: white;
  height: 100vh;

  .layout__branding {
    position: static;
  }

  .branding__wrapper {
    height: 100%;
  }

  .branding__logo {
    padding: 12px;
    margin: 0;
    height: 100%;

    .icon--logo {
      height: 100%;
    }
  }

  .branding__claim {
    margin: 0;
    display: flex;
    align-items: center;
  }
}
</style>
