export default {
  props: {
    disabled: Boolean,
    theme: String,
    border: {
      type: Boolean,
      default: true,
    },
    inline: Boolean,
  },
};
