import { brandLogos } from '@/lib/brand-logos';
/** @typedef {import("goliath/graphql/schema").Offer} Offer */

/**
 * @typedef Brand
 * @property {string} [name]
 * @property {string} [slug]
 * @property {string} [logo]
 */

/**
 * @param {string} slug
 * @returns {string|undefined}
 */
export function getBrandLogo(slug, color) {
  const currentLogoObject = brandLogos[slug];
  return (currentLogoObject?.color || color) && currentLogoObject.logoColor
    ? currentLogoObject.logoColor
    : currentLogoObject?.logoBw;
}
export function getBrandLogoMeasurements(slug) {
  const currentLogoObject = brandLogos[slug];
  return currentLogoObject?.measurements || null;
}

export function addLogo(brand, color) {
  return {
    ...brand,
    logo: getBrandLogo(brand.slug, color),
    logoMeasurements: getBrandLogoMeasurements(brand.slug),
  };
}

/**
 * @param {Offer} offer
 * @returns {?Brand}
 */
export function getBrand(offer) {
  if (!offer?.product) return {};

  if (!offer?.product?.bundle) {
    return addLogo(offer?.product?.family?.brand || {}) || {};
  }
  const bundledProducts = offer.product.bundledProducts || [];
  const mainBrand = bundledProducts.length > 0 ? bundledProducts[0].family?.brand : undefined;
  if (mainBrand && bundledProducts.every(({ family }) => family?.brand?.slug === mainBrand.slug)) {
    return addLogo(mainBrand);
  }

  return '';
}
