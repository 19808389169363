import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import ShopApi from '@/lib/shop-api';
import type { Order } from '@/lib/types/order';
import type { Item } from '@/lib/types/cartdata';
import type { RootState } from '@/types/store-types';

export interface OrderState {
  order: Order | undefined;
}

interface OrderGettersTypes {
  confirmedItems: Item[];
  confirmedOrder: Order | {};
}

export type OrderGetterTree = {
  [P in keyof OrderGettersTypes]: OrderGettersTypes[P];
};

export type OrderGetters = {
  [P in keyof OrderGettersTypes]: (
    state: OrderState,
    getters: OrderGetterTree
  ) => OrderGettersTypes[P];
};

export const state = (): OrderState => ({
  order: undefined,
});

export const mutations: MutationTree<OrderState> = {
  SET_ORDER(state, order) {
    state.order = order;
  },
};

export const getters: GetterTree<OrderState, RootState> & OrderGetters = {
  confirmedItems(_state, getters) {
    // @ts-ignore
    return getters.confirmedOrder?.items || [];
  },
  confirmedOrder(state) {
    if (!state.order) return {};
    return state.order;
  },
};

export const actions: ActionTree<OrderState, RootState> = {
  async submitOrder(_context, orderParams) {
    return ShopApi.submitOrder(orderParams);
  },
  async submitPaypalExpressOrder(_context, orderParams) {
    return ShopApi.submitPaypalExpress(orderParams);
  },
  async setConfirmedOrder(context) {
    const data = await ShopApi.confirmOrder();
    context.commit('SET_ORDER', data.order);
    return data;
  },
};
