<template>
  <header
    :class="containerClass"
    class="page-header"
  >
    <MainNavigation v-if="hasNav" />
    <DialogContainer :with-nav="hasNav" />
  </header>
</template>

<script>
import DialogContainer from '@/components/dialog-container.vue';
import MainNavigation from '@/components/navigation/main-navigation.vue';

export default {
  name: 'PageHeader',
  components: {
    DialogContainer,
    MainNavigation,
  },
  props: {
    theme: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      brandingHeight: null,
    };
  },
  computed: {
    containerClass() {
      return {
        [`page-header--${this.theme}`]: !!this.theme,
      };
    },
    hasNav() {
      return !this.theme;
    },
  },
  mounted() {
    const { branding } = this.$refs;
    if (branding) {
      this.brandingHeight = branding.$el.clientHeight;
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.page-header {
  position: sticky;
  top: 0;
  z-index: $z-index-header;
  transition: top 0.4s $nav-timing-function;

  &--small {
    border-top: 4px solid $color-primary;
    @include mq($mq-lg) {
      top: 0;
    }
  }
}

body.nav-scrolled {
  .page-header {
    @include mq($mq-lg) {
      top: $nav-shrink-offset * -1;
    }
  }
}
</style>
