import { mapActions, mapGetters } from 'vuex';
import fetchOfferCategoryBySlugs from '@/lib/goliath/offer-category-by-slugs';
import serializeCartAction from '@/lib/tealium/serializers/cart';
import StockFromOffer from '@/lib/stock-from-offer';
import { handleCartErrors } from '@/helpers/add-to-cart';

export default {
  name: 'AddToCart',
  props: {
    offer: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    quantity: {
      type: Number,
      default: 1,
      validator: (value) => value >= 1,
    },
  },
  data() {
    return {
      isSubmitting: false,
      stockQuantity: StockFromOffer.getStockFromOffer(this.offer),
      fallbackMessage: 'Hoppla! Da ist etwas schiefgelaufen. Bitte versuche es noch einmal.',
    };
  },
  watch: {
    offer(offer) {
      this.stockQuantity = StockFromOffer.getStockFromOffer(offer);
    },
  },
  computed: {
    ...mapGetters('cart', ['cartItems']),
    tealiumRel() {
      return 'content.button.in-den-warenkorb';
    },
    isDisabled() {
      if (this.isSubmitting) return true;
      return !this.inStock;
    },
    articleNumber() {
      return this.offer?.product?.articleNumber || null;
    },
    contractNumber() {
      return this.offer?.contract?.contractNumber || null;
    },
    cartItem() {
      if (this.articleNumber === null && this.contractNumber === null) return undefined;
      return this.cartItems.find(
        (item) =>
          item.articleNumber === this.articleNumber &&
          (!this.contractNumber || item.contractNumber === this.contractNumber)
      );
    },
    cartQuantity() {
      return this.cartItem?.quantity || 0;
    },
    maxQuantity() {
      return this.cartItem?.maximumAvailable || this.offer?.maxOrderableAmount || Infinity;
    },
    stockOrMaxQuantity() {
      return this.maxQuantity < this.stockQuantity ? this.maxQuantity : this.stockQuantity;
    },
    orderableQuantity() {
      // max quantity available - currently in cart - quantity to be put in the cart
      const quantity = this.stockOrMaxQuantity - this.cartQuantity - (this.quantity - 1);

      return quantity >= 0 ? quantity : 0;
    },
    inStock() {
      return this.orderableQuantity > 0;
    },
    addToCartData() {
      return {
        articleNumber: this.articleNumber,
        quantity: this.quantity,
      };
    },
  },
  emits: ['added', 'error'],
  methods: {
    ...mapActions('cart', ['addCartItems']),
    trackAddToCart(item) {
      try {
        this.$tealium.link({
          wt_link_id: this.tealiumRel,
          event_name: 'cart_add',
          ...serializeCartAction(undefined, [item]),
        });
      } catch (error) {
        // do not break the process
      }
    },
    getItem(articleNumber) {
      const item = this.cartItems.find((e) => e.articleNumber === articleNumber);
      if (item) return item;
      if (this.offer.product.articleNumber === articleNumber) {
        return {
          articleNumber: this.offer.product.articleNumber,
          wasPrice: this.offer.prices.find((p) => p.type === 'WAS')?.amount || undefined,
          teaserImage: this.offer.product.teaserImage,
          slug: this.offer.product.slug,
          name: this.offer.product.name,
          category: this.offer.product.category,
          price: this.offer.prices.find((p) => p.type === 'DEFAULT')?.amount || undefined,
        };
      }
      return undefined;
    },
    async addOfferToCart() {
      if (this.isDisabled) return;
      const { slug } = this.offer.product;
      this.isSubmitting = true;
      try {
        const [_, offerCategory] = await Promise.all([
          this.addCartItems([this.addToCartData]),
          this.$config.minimalMode
            ? Promise.resolve(undefined)
            : this.fetchOfferCategoryBySlugs({ productSlug: slug }),
        ]);
        // search Item from cart
        const item = this.getItem(this.addToCartData.articleNumber) || this.addToCartData;
        if (offerCategory) {
          item.primaryCategory = offerCategory;
        }
        this.$emit('added', item);

        this.offerAddedToCart(item);

        // Track after everything is finished for a better ux.
        this.trackAddToCart(item);
        this.trackFacebook(item);
      } catch (error) {
        this.$emit('error');
        await handleCartErrors(error, (articleNumber) => {
          const item = this.getItem(articleNumber);
          return item?.name || articleNumber;
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    offerAddedToCart(item) {
      this.$dialog.show('AddToCartDialogContent', {
        type: 'success',
        componentOptions: { item },
      });
    },
    async fetchOfferCategoryBySlugs(props) {
      try {
        return await fetchOfferCategoryBySlugs(props);
      } catch (error) {
        return undefined;
      }
    },
    trackFacebook(item) {
      try {
        this.trackFBEvent('AddToCart', {
          content_ids: [item.articleNumber],
          content_name: item.name,
          content_type: 'product',
          currency: 'EUR',
          value: item.price / 100,
          num_of_items: item.quantity,
        });
      } catch (_e) {
        // empty
      }
    },
  },
};
