<template>
  <button
    id="search-button-for-nexus"
    @click="$emit('click')"
  >
    <SvgIcon
      :class="iconClass"
      :image="searchIcon"
      no-icon-class
    />
    <slot />
  </button>
</template>

<script>
import searchIcon from '@/assets/images/navigation/search.svg';

export default {
  name: 'SearchButton',
  props: {
    iconClass: {
      type: String,
      default: null,
    },
  },
  emits: ['click'],
  data() {
    return {
      searchIcon,
    };
  },
};
</script>

<style scoped>
button {
  border: none;
  background: transparent;
  cursor: pointer;
}
</style>
