import { Store } from 'vuex';
import { ofetch, FetchOptions, FetchContext } from 'ofetch';
import type { Context } from '@nuxt/types';

interface CustomFetchContext extends FetchContext {
  loadingSpinner?: boolean;
}

interface CustomHeaders extends Headers {
  'x-csrf-token'?: string;
}

interface CustomFetchOptions extends FetchOptions {
  Headers?: CustomHeaders;
  onRequest?(context: CustomFetchContext): Promise<void> | void;
}

interface CustomFetchRequestOptions extends FetchOptions {
  loadingSpinner?: boolean;
  headers?: CustomHeaders;
}

// We declare $shopApi as global. We cannot use native $fetch, cause the x-csrf-token won't be handled.
const buildShopApiClient = (store: Store<any>) =>
  ofetch.create({
    headers: {
      Accept: 'application/vnd.td.eg-solo.v1+json',
      'Content-Type': 'application/json',
    },
    onResponse({ response }) {
      const csrfToken = response.headers.get('x-csrf-token');
      if (csrfToken) {
        store.commit('cart/SET_CSRF_TOKEN', csrfToken);
      }
      store.dispatch('loader/stop');
    },

    onRequest({ options }: { options: CustomFetchRequestOptions }) {
      if (options.loadingSpinner !== false) {
        store.dispatch('loader/start');
      }
      if (store.state.cart.csrfToken) {
        if (!options.headers) {
          // eslint-disable-next-line no-param-reassign
          options.headers = new Headers();
        }
        // eslint-disable-next-line no-param-reassign
        options.headers['x-csrf-token'] = store.state.cart.csrfToken;
      }
    },
    onResponseError({ response }) {
      const csrfToken = response?.headers?.get('x-csrf-token');
      if (csrfToken) {
        store.commit('cart/SET_CSRF_TOKEN', csrfToken);
      }
      store.dispatch('loader/stop');
    },
  } as CustomFetchOptions);

export default ({ store }: Context) => {
  globalThis.$shopApi = buildShopApiClient(store);
};
