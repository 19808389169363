export default {
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    isClosed() {
      return !this.isOpened;
    },
    isOpenable() {
      return true;
    },
    isCloseable() {
      return true;
    },
  },
  emits: ['toggle', 'open', 'close'],
  methods: {
    onOpen() {},
    onClose() {},
    async open(params = undefined) {
      if (this.isOpened || !this.isOpenable) return;
      this.isOpened = true;
      await this.onOpen(params);
      this.$emit('open', params);
    },
    async close(e = undefined) {
      if (this.isClosed || !this.isCloseable) return;
      this.isOpened = false;
      await this.onClose();
      this.$emit('close', e);
    },
    async toggle() {
      if (this.isOpened) {
        await this.close();
        this.$emit('toggle', false);
      } else {
        await this.open();
        this.$emit('toggle', true);
      }
    },
  },
};
