<template>
  <div class="branding">
    <GridContainer class="branding__container">
      <GridRow>
        <GridColumn :md="6">
          <div class="branding__wrapper branding__wrapper--left">
            <nuxt-link
              :to="indexPage"
              class="branding__logo"
              data-tealium-rel="navi-top.image.logo"
              title="Zur Startseite"
            >
              <SvgIcon :image="logo" />
            </nuxt-link>
          </div>
        </GridColumn>

        <GridColumn :md="6">
          <div class="branding__wrapper branding__wrapper--right">
            <div class="branding__links">
              <a
                class="branding__link branding__link--active"
                href="https://www.telekom.de/start"
                rel="noopener"
                target="_blank"
              >
                Privatkunden
              </a>
              <a
                class="branding__link"
                href="https://geschaeftskunden.telekom.de/"
                rel="noopener"
                target="_blank"
              >
                Geschäftskunden
              </a>
              <span class="branding__link-separator" />
              <a
                class="branding__link"
                href="https://www.telekom.de/start/telekom-shops"
                rel="noopener"
                target="_blank"
              >
                Telekom Shops
              </a>
              <a
                class="branding__link"
                href="https://www.telekom.de/kontakt"
                rel="noopener"
                target="_blank"
              >
                Kontakt
              </a>
            </div>
          </div>
        </GridColumn>
      </GridRow>
    </GridContainer>
  </div>
</template>

<script>
import { GridColumn, GridContainer, GridRow } from '@i22-td-smarthome/component-library';

import logo from '@/assets/images/logo.svg';

export default {
  name: 'Branding',
  components: {
    GridContainer,
    GridColumn,
    GridRow,
  },
  data() {
    return {
      logo,
      indexPage: {
        name: 'index',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.branding {
  display: none;
  background-color: $color-primary;
  color: $color-secondary;
  padding-top: 4px;

  @include mq($mq-md) {
    display: block;
  }

  &__container {
    max-width: $mq-lpg-lg-width + $grid-base * 4;
    padding-right: $grid-base;
    padding-left: $grid-base;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    height: 68px;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__logo {
    margin-right: $grid-base * 2;
    padding-bottom: 5px;
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: color(white);
      text-decoration: none;
    }

    .icon {
      width: 45px;
      height: 38px;
      display: block;
    }
  }

  &__links {
    display: flex;
    grid-gap: $grid-base * 1.5;
  }

  &__link {
    color: color(white);
    @include font-size(14px, 16px);
    font-weight: 800;
    border-bottom: 1px solid transparent;
    padding-top: 4px;
    padding-bottom: 3px;

    &--active,
    &:hover {
      text-decoration: none;
      border-bottom-color: color(white);
    }
  }

  &__link-separator {
    display: inline-block;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
}
</style>
