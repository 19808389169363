import type { Plugin } from '@nuxt/types';
import Tealium from '@/lib/tealium';

/**
 * usage:
 * Composition-API:
 * useContext().$tealium?.link(PARAMS);
 *
 * Options-API
 * this.$tealium.link(PARAMS)
 */
const tealiumPlugin: Plugin = (_context, inject) => {
  inject('tealium', Tealium);
};

export default tealiumPlugin;
