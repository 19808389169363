import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { ref, provide } from '@nuxtjs/composition-api';
import featureLib from '@/lib/features';

/**
 *
 * To avoid SSR / CSR mismatch,
 * we need the "defaultFeatures" at page-load and set the custom (LocalStorage) features after the page is loaded.
 * This Plugin is called one time per App- / Page-loaded and will load all saved Features from LocalStorage.
 *
 * When using what:
 *
 * featuresClient: in client-side-only code
 * featuresAsync: In template, for computed props, etc. It is reactive, so you'll maybe need a watcher!
 *
 * When you are not sure: use featuresAsync!
 */

let calledOnetime = false;

const featuresAsync = ref(JSON.parse(JSON.stringify(featureLib.defaultFeatures)));
const featuresClient = ref(JSON.parse(JSON.stringify(featureLib)));

Vue.use({
  install: (app) => {
    app.mixin({
      created() {
        if (!process.client) return;

        // We wait a litte time, till SSR is ready and "switch" the feature flags to localStorage.
        setTimeout(() => {
          if (calledOnetime) return;
          calledOnetime = true;
          featuresAsync.value = featureLib;
        }, 100);
      },
    });
  },
});

const featuresPlugin: Plugin = ({ app }) => {
  // eslint-disable-next-line no-param-reassign
  app.setup = () => {
    provide('featuresAsync', featuresAsync);
    provide('featuresClient', featuresClient);
  };
};

export default featuresPlugin;
