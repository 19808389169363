<template>
  <main class="error">
    <GridContainer>
      <div v-if="error.productSlug">
        <div class="error__header-container">
          <div class="error__header">
            <Heading
              align="center"
              spaces-top
            >
              Entschuldigung
            </Heading>
            <div class="text--center error__bubble">
              <SvgIcon
                :image="shape"
                class="error__bubble-shape"
                no-icon-class
              />
              <div class="error__bubble-content">Leider<br />ausverkauft!</div>
            </div>
          </div>
        </div>
        <client-only>
          <ProductNotAvailable :product-slug="error.productSlug" />
        </client-only>
      </div>
      <div
        v-else
        class="error__global"
      >
        <ImageElement
          :src="image"
          align="center"
          alt="Entschuldigung"
        />

        <Heading
          spaces-top
          align="center"
        >
          {{ heading }}
        </Heading>

        <Paragraph
          align="center"
          v-html="content"
        />

        <template v-if="isDev">
          <p>
            Message:<br /><strong>{{ error.message }}</strong>
          </p>

          <p v-if="parsedData?.url">
            Url:<br /><strong>{{ parsedData.url }}</strong>
          </p>

          <span>Stack:</span><br />
          <div
            style="overflow: auto; font-size: 0.8rem"
            v-html="parsedData?.stack || error?.stack"
          />
        </template>
      </div>
    </GridContainer>
  </main>
</template>

<script>
import {
  GridContainer,
  Heading,
  ImageElement,
  Paragraph,
} from '@i22-td-smarthome/component-library';
import PageHeader from '@/mixins/page-header';

import shape from '@/assets/images/shape-404-leider-ausverkauft.svg';

export default {
  name: 'ErrorPage',
  components: {
    ProductNotAvailable: () => import('@/components/product-not-available.vue'),
    GridContainer,
    Heading,
    ImageElement,
    Paragraph,
  },
  mixins: [PageHeader],
  props: {
    error: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isDev: !this.$config.mode.isProduction,
      shape,
    };
  },
  computed: {
    errorStatus404() {
      return this.error.statusCode === 404;
    },
    title() {
      return 'Fehler';
    },
    isIndexed() {
      return false;
    },
    heading() {
      if (this.errorStatus404) return 'Entschuldigung';
      return 'shop.telekom.de ist nicht erreichbar';
    },
    image() {
      if (this.errorStatus404)
        return '/api/content/images/error/2c71ef9aee89b85b64682f32c15b4615/icon-404.png';
      return '/api/content/images/error/c8d9690adc45f0ed51753da7ea62569b/icon-500.png';
    },
    content() {
      if (this.errorStatus404)
        return 'Hier haben wir uns wohl in den Einstellungen vertan – oder Du hast eine Seite aufgerufen, die nicht mehr existiert. Während wir im Hintergrund nach einem möglichen Fehler suchen, schaue doch einfach auf der <a href="/" class="link" data-tealium-rel="content.text.startseite">Startseite</a> vorbei und lasse Dich inspirieren – oder besuche direkt unseren <a href="/smartphones-und-handys" class="link"  data-tealium-rel="content.text.shop">Shop</a>.';
      return 'Wir wurden automatisch über den Fehler benachrichtigt. Bitte versuche, die Seite neu zu laden. Sollte dies nicht zum Erfolg führen, probiere es bitte in wenigen Minuten erneut.';
    },
    parsedData() {
      try {
        return JSON.parse(this.error?.data || '');
      } catch {
        return undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/base';

.error {
  margin-top: $grid-base * 2;
  margin-bottom: $grid-base * 2;

  @include mq($mq-sm) {
    margin-top: $grid-base * 8;
    margin-bottom: $grid-base * 8;
  }

  &__global {
    width: 100%;
    max-width: $mq-md * 0.5;
    margin-inline: auto;
  }

  &__header-container {
    padding-top: $grid-base * 12;
    text-align: center;

    @include mq($mq-sm) {
      padding-top: $grid-base * 10;
    }

    @include mq($mq-md) {
      padding-top: $grid-base * 6;
    }
  }

  &__header {
    display: inline-block;
    position: relative;
  }

  &__bubble {
    position: absolute;
    left: 30%;
    top: -80%;
    color: $color-secondary;

    @include mq($mq-md) {
      left: 120%;
      top: 20%;
    }

    &-shape {
      overflow: visible;
      position: absolute;
      left: 0;
    }

    &-content {
      position: relative;
      font-weight: 700;
      @include font-size(24px);
      margin-top: 8%;
    }
  }
}
</style>
