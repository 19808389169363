<template>
  <BaseLayout theme-footer="standard" />
</template>

<script>
import Layout from '@/mixins/layout';
import BaseLayout from '@/components/base-layout.vue';

export default {
  name: 'DefaultLayout',
  components: {
    BaseLayout,
  },
  mixins: [Layout],
};
</script>
