import type { GetterTree, MutationTree } from 'vuex';
import type { ProductCategoriesQuery, NavigationTeasersQuery } from '@/lib/goliath/goliath.types';
import type { Promotion } from '@/lib/offers-by-group';
import type { RootState } from '@/types/store-types';

export interface NavigationTeaser extends Promotion {
  heading: string;
  subheading: string;
  customClass?: string;
  image: string;
  imagePosition?: string;
  link: {
    label: string;
    path?: string;
    theme?: string;
  };
  theme?: string;
  tileGroups: string[];
}

type Offer = NonNullable<
  NonNullable<NonNullable<NavigationTeasersQuery['offers']>['entries']>[number]
>;

export interface NavigationTeaserWithOffer extends NavigationTeaser {
  offer?: Offer;
}

type ListItem = {
  name: string;
  path: string;
};

export type Category = {
  name: string;
  path: string;
  // this appears under "Kategorien"
  children?: {
    name: string;
    path: string;
  }[];
  // this appears under "Inhalte"
  listItems?: ListItem[];
  buttonLabel?: string;
};

export interface NavigationState {
  navigationItems: Category[];
  teaserOffers: NavigationTeaserWithOffer[];
}

interface NavigationGettersTypes {
  navigationItems: Category[];
  teaserOffers: NavigationTeaserWithOffer[];
}

export type NavigationGetterTree = {
  [P in keyof NavigationGettersTypes]: NavigationGettersTypes[P];
};

export type NavigationGetters = {
  [P in keyof NavigationGettersTypes]: (
    state: NavigationState,
    getters: NavigationGetterTree
  ) => NavigationGettersTypes[P];
};

export type GoliathCategory = NonNullable<
  NonNullable<ProductCategoriesQuery['productCategories']>['entries']
>[number];

export const TEASER_DATA: NavigationTeaser[] = [
  {
    articleNumber: '99935421',
    heading: 'Telekom T Phone 2',
    subheading: 'Einsteigerphone<br/>zum Spitzenpreis',
    image:
      '/api/content/images/navigation-teasers/441527d1af2dc04819deb98d1e364452/teaser-t-phone-2-bg@2x.jpg',
    link: {
      label: 'Zum T Phone 2',
    },
    theme: 'light',
    tileGroups: ['smartphones-und-handys'],
  },
  {
    articleNumber: '99935433',
    heading: 'Fairphone 5',
    subheading: 'Das nachhaltige Smartphone',
    image:
      '/api/content/images/navigation-teasers/5308d8134f05d96695f9855b91f75777/teaser-fairphone-bg@2x.jpg',
    link: {
      label: 'Zum Fairphone',
    },
    tileGroups: ['smartphones-und-handys'],
  },
  {
    articleNumber: '6941948702417',
    heading: 'XIAOMI TV A 2025 Pro 75 Zoll',
    subheading: 'Für ein Immersives visuelles Erlebnis',
    image:
      '/api/content/images/navigation-teasers/517d5fcae770418a42ce22f263042e32/teaser-xiaomi-tv@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['tv'],
  },
  {
    articleNumber: '8806095593692',
    heading: 'Samsung 43 Zoll Crystal UHD 4K 2024 DU7170',
    subheading: 'PurColor Technologie für lebensechte<br/>Farben',
    image:
      '/api/content/images/navigation-teasers/65b3ed7601096de6a1b62183cb2b69b4/teaser-samsung-tv@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['tv'],
  },

  {
    articleNumber: '99935296',
    heading: 'Apple 13" iPad Air (2024) Wi-Fi + 5G',
    subheading: 'Mit Liquid Retina Display',
    image:
      '/api/content/images/navigation-teasers/faeee6e21c10057b1b56cb13d43e9a5a/teaser-ipad@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['tablets-und-surfsticks'],
  },
  {
    articleNumber: '6941812730355',
    heading: 'XIAOMI Pad 6 6/128GB Tablet',
    subheading: 'schlankes Design mit Metalloberfläche',
    image:
      '/api/content/images/navigation-teasers/85ea98f62f734db524f01ba60e6b1050/teaser-xiaomi-pad@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['tablets-und-surfsticks'],
  },
  {
    articleNumber: '99934414',
    heading: 'Samsung Galaxy Tab S9+ 5G',
    subheading: 'deine kreativen Ideen schnell zum Leben erweckt',
    image:
      '/api/content/images/navigation-teasers/1da7d953e4cf606c74f4997600c72caa/teaser-galaxy-tab-s9-5g@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['tablets-und-surfsticks'],
  },

  {
    articleNumber: '8806095076119',
    heading: 'Samsung Galaxy Watch6 Classic LTE',
    subheading: 'Sportliches Design mit robustem<br/>Saphirglas',
    image:
      '/api/content/images/navigation-teasers/00cdb156af9b55cdfdfc838a7bc00dfd/samsung-galaxy-watch@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['smartwatches'],
  },
  {
    articleNumber: '6941812764350',
    heading: 'XIAOMI Watch 2',
    subheading: 'Schlankes Design mit leichtem Gehäuse',
    image:
      '/api/content/images/navigation-teasers/102d74e4c00d39196daa6ffac1bd64c6/teaser-xiaomi-watch@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['smartwatches'],
  },
  {
    articleNumber: '6941812763285',
    heading: 'XIAOMI Smart Band 8 Pro',
    subheading: 'dein Täglicher TrainignsBegleiter',
    image:
      '/api/content/images/navigation-teasers/d7b7ea1b0140814be447e8120c6df06d/teaser-xiaomi-watch-band-8-pro@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['smartwatches'],
  },
  {
    heading: 'MagentaZuhause App',
    subheading: 'Deine Smarten Geräte mit Einer App vernetzen',
    image:
      '/api/content/images/navigation-teasers/da7336118f2ca99683031ffa89a9f546/teaser-smarthome@2x.jpg',
    link: {
      label: 'Mehr erfahren',
      path: '/smart-home-entdecken',
      theme: 'transparent',
    },
    tileGroups: ['smart-home'],
  },
  {
    heading: 'Smarte Gerätevielfalt',
    subheading: 'Für Mehr Komfort mit einem smarten Zuhause',
    image:
      '/api/content/images/navigation-teasers/7037f2316c6635932fc4e8b32acc4f7f/teaser-smarthome-geraete@2x.jpg',
    link: {
      label: 'Alle Geräte im Überblick',
      path: '/smart-home',
      theme: 'transparent',
    },
    tileGroups: ['smart-home'],
  },
  {
    articleNumber: '4048945030085',
    heading: 'Teufel Rockster Cross Portable',
    subheading: 'Bluetooth-Stereo-Speaker der Spitzenklasse',
    customClass: 'teufel',
    image:
      '/api/content/images/navigation-teasers/ea4b7749549d742dd83697c1e6acc0d7/teaser-teufel-box@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['zubehoer'],
  },
  {
    articleNumber: '0194644098018OT',
    heading: 'Anker 3-in-1 Cube with MagSafe',
    subheading: 'Der Multi-device&shy;schnelllader',
    image:
      '/api/content/images/navigation-teasers/8fdc12d56d65dab4cb500ced2901bf36/teaser-anker-cube@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['zubehoer'],
  },
  {
    articleNumber: '6934177780554',
    heading: 'XIAOMI Smart Blender',
    subheading: 'Frische<br/>Smoothies Auf<br/>Knopfdruck',
    image:
      '/api/content/images/navigation-teasers/4fd65749a730cfc950eaf75ed449c4a4/teaser-xiaomi-blender@2x.jpg',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['zubehoer'],
  },
];

export const state = (): NavigationState => ({
  /**
   * We have to remove entries, sort them and add custom content to the goliath data.
   * We switch the logic. This Collection will be extended with the children from goliath.
   */
  navigationItems: [
    {
      name: 'Smartphones',
      path: 'smartphones-und-handys',
      children: [],
    },
    {
      name: 'Tablets',
      path: 'tablets-und-surfsticks',
      children: [],
    },
    {
      name: 'Smartwatches',
      path: 'smartwatches',
      children: [],
    },
    {
      name: 'Smart Home',
      path: 'smart-home',
      children: [],
    },
    {
      name: 'TV',
      path: 'tv',
      children: [],
    },
    {
      name: 'Zubehör',
      path: 'zubehoer',
      children: [],
    },
    {
      name: 'Themenwelten',
      path: 'themenwelten',
      listItems: [
        {
          name: 'Smart Home',
          path: 'smart-home-entdecken',
        },
        {
          name: 'agood company',
          path: 'a-good-company',
        },
        {
          name: 'Xiaomi',
          path: 'xiaomi',
        },
        {
          name: 'Displex Display-Schutz',
          path: 'displex',
        },
        {
          name: 'Google',
          path: 'google',
        },
        {
          name: 'Samsung',
          path: 'samsung',
        },
        {
          name: 'Anker',
          path: 'anker',
        },
      ],
    },
    {
      name: 'Aktionen',
      path: 'preisvorteile-sichern',
      children: [],
    },
  ],
  teaserOffers: [],
});

export const mutations: MutationTree<NavigationState> = {
  SET_PRODUCT_CATEGORIES(state, productCategories: Category[]) {
    state.navigationItems = productCategories;
  },
  SET_TEASER_OFFERS(state, teaserOffers: NavigationTeaserWithOffer[]) {
    state.teaserOffers = teaserOffers;
  },
  SET_LIST_ITEMS(state, params: { item: string; listItems: ListItem[] }) {
    const navigationItem = state.navigationItems.find((item) => item.path === params.item);
    if (navigationItem) navigationItem.listItems = params.listItems;
  },
};

export const getters: GetterTree<NavigationState, RootState> & NavigationGetters = {
  navigationItems(state) {
    return state.navigationItems;
  },
  teaserOffers(state) {
    return state.teaserOffers;
  },
};
