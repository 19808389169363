import axios from 'axios';
import { baseUrl, hostingAuth } from '@/lib/public-runtime-config';

export function buildPlainClient(options = {}) {
  const newOptions = {};
  if (baseUrl) {
    newOptions.baseURL = baseUrl;
  }
  if (hostingAuth) {
    newOptions.auth = hostingAuth;
  }
  return axios.create({ ...options, ...newOptions });
}

export function buildJsonClient() {
  return buildPlainClient({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}
