<template>
  <MainNavigationItem
    :expandable="!simpleNavigationItem"
    :label="navigationItem.name"
    :single-level-navigation="showSingleLevelNavigation"
    :to="getNavigationLink(navigationItem.path)"
    :additional-class="navigationItem.additionalClass"
    :additional-link-class="navigationItem.additionalLinkClass"
    :level="level"
    exact
    :class="{ 'navigation-item__main-navigation-item--with-teasers': hasTeasers }"
    @subnav-open="$emit('subnav-open', $event)"
  >
    <SubNavigationList
      v-if="hasSubNavListItems"
      :items="subNavListItems"
      :expanded="!hasSubCategories"
      @subnav-open="$emit('subnav-open', $event)"
    />
    <SubNavigationList
      v-if="hasSubCategories && !isSmartphones"
      :category-path="navigationItem.path"
      :items="subCategories"
      :expanded="!hasSubNavListItems"
      @subnav-open="$emit('subnav-open', $event)"
    />
    <div
      v-if="hasTeasers && currentNavItemTeasers?.length > 0"
      class="navigation-item__teasers-wrapper"
    >
      <NavigationTeaser
        v-for="teaser in currentNavItemTeasers"
        :key="teaser.articleNumber"
        :teaser-data="teaser"
        :column-number="columnNumber"
      />
    </div>
  </MainNavigationItem>
</template>

<script>
import MainNavigationItem from '@/components/navigation/main-navigation-item.vue';
import SubNavigationList from '@/components/navigation/sub-navigation-list.vue';
import NavigationTeaser from '@/components/navigation/navigation-teaser.vue';
import { mapGetters } from 'vuex';
import { getOffersByGroup } from '@/lib/offers-by-group';
import getDevice from '@/lib/detect-device';

export default {
  name: 'NavigationItem',
  components: {
    MainNavigationItem,
    SubNavigationList,
    NavigationTeaser,
  },
  data() {
    return {
      isDesktop: false,
    };
  },
  emits: ['subnav-open'],
  props: {
    navigationItem: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.isDesktop = getDevice() === 'Desktop';
  },
  computed: {
    ...mapGetters('navigation', ['teaserOffers']),
    subNavListItems() {
      return this.navigationItem?.listItems || [];
    },
    hasSubNavListItems() {
      return this.subNavListItems.length > 0;
    },
    subCategories() {
      return this.navigationItem.children || null;
    },
    hasSubCategories() {
      return this.subCategories?.length > 0;
    },
    hasTeasers() {
      return (
        this.currentNavItemTeasers?.length > 0 &&
        this.$screenSize.isAtLeastMediumDesktopScreen.value
      );
    },
    simpleNavigationItem() {
      return !this.hasSubCategories && !this.hasSubNavListItems && !this.hasTeasers;
    },
    showSingleLevelNavigation() {
      const possibleCases = [this.hasSubNavListItems, this.hasSubCategories, this.hasTeasers];

      const trueCount = possibleCases.filter(Boolean).length;
      return trueCount === 1;
    },
    currentNavItemTeasers() {
      return getOffersByGroup(this.teaserOffers, this.navigationItem.path, 2);
    },
    columnNumber() {
      if (this.navigationItem?.children?.length > 20 || this.navigationItem?.listItems?.length > 20)
        return 'three';
      if (this.navigationItem?.children?.length > 10 || this.navigationItem?.listItems?.length > 10)
        return 'two';
      return 'one';
    },
    isSmartphones() {
      return this.navigationItem?.path === 'smartphones-und-handys'; // until brodos fixes the subcategories they set up
    },
  },
  methods: {
    getNavigationLink(path) {
      if (!path) return '';
      return `/${path}`;
    },
    getOffersByGroup,
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

:deep(.main-navigation-item__subnav-wrapper:has(.navigation-item__smart-home-links--desktop)) {
  column-gap: 0;
}

.navigation-item {
  &__teasers-wrapper {
    display: flex;
    align-items: flex-end;
    column-gap: 3 * $grid-base;
    row-gap: 2 * $grid-base;
    flex-wrap: wrap;
  }

  &__main-navigation-item {
    &--with-teasers {
      :deep(.main-navigation-item__subnav-wrapper) {
        @include mq($mq-lg) {
          justify-content: space-between;
          column-gap: 2 * $grid-base;
        }
      }
      :deep(.subnav-list) {
        @include mq($mq-lg) {
          column-gap: 4 * $grid-base;
        }
      }
    }
  }
}
</style>
