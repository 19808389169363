<template>
  <div class="legal-modal-button">
    <button
      ref="button"
      type="button"
      class="btn btn--footnote"
      @click.stop="open"
    >
      *
    </button>
    <Modal
      ref="modal"
      @close="$refs.button.focus()"
    >
      <template v-if="hasTexts">
        <div
          v-for="(text, index) in texts"
          :key="index"
          class="legal-modal-button__text"
        >
          <div v-html="text"></div>
        </div>
      </template>
      <slot name="modal" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/modal.vue';

export default {
  name: 'LegalModalButton',
  components: {
    Modal,
  },
  props: {
    texts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasTexts() {
      return this.texts && this.texts.length > 0;
    },
  },
  methods: {
    open() {
      this.$refs.modal.open('legal-modal-button');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.legal-modal-button {
  display: inline-block;

  &__text {
    &:last-child p:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
