import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _632c3fd1 = () => interopDefault(import('../pages/a-good-company.vue' /* webpackChunkName: "pages/a-good-company" */))
const _791417f4 = () => interopDefault(import('../pages/anker/index.vue' /* webpackChunkName: "pages/anker/index" */))
const _6bc62dd2 = () => interopDefault(import('../pages/bitte-kommen-sie-spaeter-wieder.vue' /* webpackChunkName: "pages/bitte-kommen-sie-spaeter-wieder" */))
const _55ab4cf5 = () => interopDefault(import('../pages/displex.vue' /* webpackChunkName: "pages/displex" */))
const _a60dc7a0 = () => interopDefault(import('../pages/displex-fullcover.vue' /* webpackChunkName: "pages/displex-fullcover" */))
const _3cfbd130 = () => interopDefault(import('../pages/displex-privacy.vue' /* webpackChunkName: "pages/displex-privacy" */))
const _01472cee = () => interopDefault(import('../pages/displex-tablet.vue' /* webpackChunkName: "pages/displex-tablet" */))
const _6c91ee74 = () => interopDefault(import('../pages/google/index.vue' /* webpackChunkName: "pages/google/index" */))
const _df1787b4 = () => interopDefault(import('../pages/greyp-e-bikes.vue' /* webpackChunkName: "pages/greyp-e-bikes" */))
const _5a9d189f = () => interopDefault(import('../pages/handyvergleich.vue' /* webpackChunkName: "pages/handyvergleich" */))
const _4d64664c = () => interopDefault(import('../pages/handyvergleich-samsung.vue' /* webpackChunkName: "pages/handyvergleich-samsung" */))
const _070d5c4e = () => interopDefault(import('../pages/handyvergleich-xiaomi.vue' /* webpackChunkName: "pages/handyvergleich-xiaomi" */))
const _72726fd6 = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _c018ed50 = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _6fb57793 = () => interopDefault(import('../pages/magenta-speaker.vue' /* webpackChunkName: "pages/magenta-speaker" */))
const _e25b0732 = () => interopDefault(import('../pages/magenta-tv-hardware.vue' /* webpackChunkName: "pages/magenta-tv-hardware" */))
const _466b7bcb = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e21440e4 = () => interopDefault(import('../pages/merkliste.vue' /* webpackChunkName: "pages/merkliste" */))
const _a08f08d2 = () => interopDefault(import('../pages/nudient.vue' /* webpackChunkName: "pages/nudient" */))
const _256e44e8 = () => interopDefault(import('../pages/nudient-schutzhuellen.vue' /* webpackChunkName: "pages/nudient-schutzhuellen" */))
const _7aed9219 = () => interopDefault(import('../pages/preisvorteile-sichern/index.vue' /* webpackChunkName: "pages/preisvorteile-sichern/index" */))
const _2b5a6866 = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _10fa4c84 = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _33a9a635 = () => interopDefault(import('../pages/samsung/index.vue' /* webpackChunkName: "pages/samsung/index" */))
const _4d90b64b = () => interopDefault(import('../pages/smart-home-entdecken.vue' /* webpackChunkName: "pages/smart-home-entdecken" */))
const _f414bb9a = () => interopDefault(import('../pages/stabiles-internet.vue' /* webpackChunkName: "pages/stabiles-internet" */))
const _183bd8fc = () => interopDefault(import('../pages/themenwelten.vue' /* webpackChunkName: "pages/themenwelten" */))
const _4d3ef633 = () => interopDefault(import('../pages/xiaomi.vue' /* webpackChunkName: "pages/xiaomi" */))
const _e50cca98 = () => interopDefault(import('../pages/xiaomi-smartphones.vue' /* webpackChunkName: "pages/xiaomi-smartphones" */))
const _c37a3288 = () => interopDefault(import('../pages/xiaomi-zubehoer.vue' /* webpackChunkName: "pages/xiaomi-zubehoer" */))
const _49d00f40 = () => interopDefault(import('../pages/zahlung-per-handyrechnung.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung" */))
const _6dceb3ea = () => interopDefault(import('../pages/zahlung-per-handyrechnung-gewinnspiel-registrieren.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung-gewinnspiel-registrieren" */))
const _65d33101 = () => interopDefault(import('../pages/anker/product-data.ts' /* webpackChunkName: "pages/anker/product-data" */))
const _1f791fb9 = () => interopDefault(import('../pages/google/product-data.js' /* webpackChunkName: "pages/google/product-data" */))
const _38773f25 = () => interopDefault(import('../pages/hilfe/lieferstatus-und-retoure.vue' /* webpackChunkName: "pages/hilfe/lieferstatus-und-retoure" */))
const _6f3e43f0 = () => interopDefault(import('../pages/samsung/product-data.ts' /* webpackChunkName: "pages/samsung/product-data" */))
const _c8deef56 = () => interopDefault(import('../pages/shop/bestaetigung.vue' /* webpackChunkName: "pages/shop/bestaetigung" */))
const _44d7b374 = () => interopDefault(import('../pages/shop/brodos-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/brodos-zahlungsverifizierung" */))
const _42cff0e1 = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _125e80f4 = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _25550989 = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _7a6cd62d = () => interopDefault(import('../pages/shop/kundenformular/persoenliche-daten.vue' /* webpackChunkName: "pages/shop/kundenformular/persoenliche-daten" */))
const _9d2eb1ac = () => interopDefault(import('../pages/shop/kundenformular/zahlungsart.vue' /* webpackChunkName: "pages/shop/kundenformular/zahlungsart" */))
const _0ec4187a = () => interopDefault(import('../pages/shop/telekom-bestaetigung.vue' /* webpackChunkName: "pages/shop/telekom-bestaetigung" */))
const _1b0219d0 = () => interopDefault(import('../pages/shop/telekom-bezahlung-information.vue' /* webpackChunkName: "pages/shop/telekom-bezahlung-information" */))
const _c52700ec = () => interopDefault(import('../pages/shop/telekom-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/telekom-zahlungsverifizierung" */))
const _b62f1678 = () => interopDefault(import('../pages/shop/warenkorb.vue' /* webpackChunkName: "pages/shop/warenkorb" */))
const _1bfa2190 = () => interopDefault(import('../pages/shop/zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/zahlungsverifizierung" */))
const _3448f7c0 = () => interopDefault(import('../pages/shop/zusammenfassung.vue' /* webpackChunkName: "pages/shop/zusammenfassung" */))
const _69cce1fa = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _204dbe25 = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _b5de05d2 = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _9227df0e = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _00caf20a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27b5e5c8 = () => interopDefault(import('../pages/geraete/_slug.vue' /* webpackChunkName: "pages/geraete/_slug" */))
const _93d59938 = () => interopDefault(import('../pages/preisvorteile-sichern/_aktion.vue' /* webpackChunkName: "pages/preisvorteile-sichern/_aktion" */))
const _287f8280 = () => interopDefault(import('../special-pages/shop-list' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-good-company",
    component: _632c3fd1,
    name: "a-good-company"
  }, {
    path: "/anker",
    component: _791417f4,
    name: "anker"
  }, {
    path: "/bitte-kommen-sie-spaeter-wieder",
    component: _6bc62dd2,
    name: "bitte-kommen-sie-spaeter-wieder"
  }, {
    path: "/displex",
    component: _55ab4cf5,
    name: "displex"
  }, {
    path: "/displex-fullcover",
    component: _a60dc7a0,
    name: "displex-fullcover"
  }, {
    path: "/displex-privacy",
    component: _3cfbd130,
    name: "displex-privacy"
  }, {
    path: "/displex-tablet",
    component: _01472cee,
    name: "displex-tablet"
  }, {
    path: "/google",
    component: _6c91ee74,
    name: "google"
  }, {
    path: "/greyp-e-bikes",
    component: _df1787b4,
    name: "greyp-e-bikes"
  }, {
    path: "/handyvergleich",
    component: _5a9d189f,
    name: "handyvergleich"
  }, {
    path: "/handyvergleich-samsung",
    component: _4d64664c,
    name: "handyvergleich-samsung"
  }, {
    path: "/handyvergleich-xiaomi",
    component: _070d5c4e,
    name: "handyvergleich-xiaomi"
  }, {
    path: "/infos-zum-online-kauf",
    component: _72726fd6,
    name: "infos-zum-online-kauf"
  }, {
    path: "/kontakt",
    component: _c018ed50,
    name: "kontakt"
  }, {
    path: "/magenta-speaker",
    component: _6fb57793,
    name: "magenta-speaker"
  }, {
    path: "/magenta-tv-hardware",
    component: _e25b0732,
    name: "magenta-tv-hardware"
  }, {
    path: "/maintenance",
    component: _466b7bcb,
    name: "maintenance"
  }, {
    path: "/merkliste",
    component: _e21440e4,
    name: "merkliste"
  }, {
    path: "/nudient",
    component: _a08f08d2,
    name: "nudient"
  }, {
    path: "/nudient-schutzhuellen",
    component: _256e44e8,
    name: "nudient-schutzhuellen"
  }, {
    path: "/preisvorteile-sichern",
    component: _7aed9219,
    name: "preisvorteile-sichern"
  }, {
    path: "/ratenzahlung",
    component: _2b5a6866,
    name: "ratenzahlung"
  }, {
    path: "/redirecting",
    component: _10fa4c84,
    name: "redirecting"
  }, {
    path: "/samsung",
    component: _33a9a635,
    name: "samsung"
  }, {
    path: "/smart-home-entdecken",
    component: _4d90b64b,
    name: "smart-home-entdecken"
  }, {
    path: "/stabiles-internet",
    component: _f414bb9a,
    name: "stabiles-internet"
  }, {
    path: "/themenwelten",
    component: _183bd8fc,
    name: "themenwelten"
  }, {
    path: "/xiaomi",
    component: _4d3ef633,
    name: "xiaomi"
  }, {
    path: "/xiaomi-smartphones",
    component: _e50cca98,
    name: "xiaomi-smartphones"
  }, {
    path: "/xiaomi-zubehoer",
    component: _c37a3288,
    name: "xiaomi-zubehoer"
  }, {
    path: "/zahlung-per-handyrechnung",
    component: _49d00f40,
    name: "zahlung-per-handyrechnung"
  }, {
    path: "/zahlung-per-handyrechnung-gewinnspiel-registrieren",
    component: _6dceb3ea,
    name: "zahlung-per-handyrechnung-gewinnspiel-registrieren"
  }, {
    path: "/anker/product-data",
    component: _65d33101,
    name: "anker-product-data"
  }, {
    path: "/google/product-data",
    component: _1f791fb9,
    name: "google-product-data"
  }, {
    path: "/hilfe/lieferstatus-und-retoure",
    component: _38773f25,
    name: "hilfe-lieferstatus-und-retoure"
  }, {
    path: "/samsung/product-data",
    component: _6f3e43f0,
    name: "samsung-product-data"
  }, {
    path: "/shop/bestaetigung",
    component: _c8deef56,
    name: "shop-bestaetigung"
  }, {
    path: "/shop/brodos-zahlungsverifizierung",
    component: _44d7b374,
    name: "shop-brodos-zahlungsverifizierung"
  }, {
    path: "/shop/fehler",
    component: _42cff0e1,
    name: "shop-fehler"
  }, {
    path: "/shop/kundenformular",
    component: _125e80f4,
    children: [{
      path: "",
      component: _25550989,
      name: "shop-kundenformular"
    }, {
      path: "persoenliche-daten",
      component: _7a6cd62d,
      name: "shop-kundenformular-persoenliche-daten"
    }, {
      path: "zahlungsart",
      component: _9d2eb1ac,
      name: "shop-kundenformular-zahlungsart"
    }]
  }, {
    path: "/shop/telekom-bestaetigung",
    component: _0ec4187a,
    name: "shop-telekom-bestaetigung"
  }, {
    path: "/shop/telekom-bezahlung-information",
    component: _1b0219d0,
    name: "shop-telekom-bezahlung-information"
  }, {
    path: "/shop/telekom-zahlungsverifizierung",
    component: _c52700ec,
    name: "shop-telekom-zahlungsverifizierung"
  }, {
    path: "/shop/warenkorb",
    component: _b62f1678,
    name: "shop-warenkorb"
  }, {
    path: "/shop/zahlungsverifizierung",
    component: _1bfa2190,
    name: "shop-zahlungsverifizierung"
  }, {
    path: "/shop/zusammenfassung",
    component: _3448f7c0,
    name: "shop-zusammenfassung"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _69cce1fa,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _204dbe25,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _b5de05d2,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _9227df0e,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/",
    component: _00caf20a,
    name: "index"
  }, {
    path: "/geraete/:slug?",
    component: _27b5e5c8,
    name: "geraete-slug"
  }, {
    path: "/preisvorteile-sichern/:aktion?",
    component: _93d59938,
    name: "preisvorteile-sichern-aktion"
  }, {
    path: "/:config+",
    component: _287f8280,
    name: "shop-list"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
