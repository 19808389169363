<template>
  <div>
    <div class="dialog-content__header">
      <SvgIcon
        :image="successIcon"
        class="icon dialog-container__icon dialog-container__icon--success"
      />
      <strong>{{ item.name }}</strong>
      wurde in den Warenkorb gelegt
    </div>
    <div
      v-if="additionalNote"
      class="dialog-content__add-to-cart-message"
    >
      <span v-html="additionalNote" />
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3 grid__item--xl-2">
        <nuxt-link
          class="btn btn--transparent dialog-content__button"
          to="/shop/warenkorb"
        >
          Warenkorb ansehen
        </nuxt-link>
      </div>
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3 grid__item--xl-2">
        <a
          class="btn btn--primary dialog-content__button"
          @click="closeFlyout"
        >
          Weiter einkaufen
        </a>
      </div>
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <div
        :class="{ 'grid__item--spacing-2': hasRecommendations }"
        class="grid__item grid__item--12"
      ></div>
    </div>

    <template v-if="hasRecommendations">
      <h3 class="text--h3 text--default-color">Vielleicht interessierst Du Dich auch für…</h3>

      <GridContainer>
        <OfferTiles
          :offers="truncatedRecommendedOffers"
          class="dialog-container__recommendations"
        />
      </GridContainer>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogContent from '@/mixins/dialog-content';

import { GridContainer } from '@i22-td-smarthome/component-library';

import OfferTiles from '@/components/offer-tiles.vue';
import successIcon from '@/assets/images/check.svg';

export default {
  name: 'AddToCartDialogContent',
  components: {
    GridContainer,
    OfferTiles,
  },
  mixins: [
    DialogContent({
      item: null,
      recommendedOffers: [],
    }),
  ],
  data() {
    return {
      successIcon,
      additionalNote: undefined,
    };
  },
  async mounted() {
    await this.$nextTick();

    // Is currently not used.
    // await this.getAdditionalNote();

    await this.fetchOfferRecommendations();
  },
  computed: {
    ...mapGetters('cart', ['checkoutInitUrlSuffix', 'cartItems']),
    articleNumber() {
      return this.item.articleNumber;
    },
    hasRecommendations() {
      return this.recommendedOffers.length > 0;
    },
    truncatedRecommendedOffers() {
      return this.recommendedOffers.slice(0, 4);
    },
    tariffInCart() {
      return this.cartItems.some((item) => item.contractNumber === '89948287');
    },
  },
  methods: {
    closeFlyout() {
      this.$dialog.hide();
    },
    async fetchOfferRecommendations() {
      if (!this.articleNumber) return;
      try {
        const data = await fetch('/api/goliath/offer-recommendations', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            articleNumber: this.articleNumber,
          }),
        }).then((r) => r.json());

        this.recommendedOffers = data?.recommendations || [];
      } catch (error) {
        console.error(error);
      }
    },
    async getAdditionalNote() {
      try {
        const content = await fetch(
          `/api/content/data/hooks/item-added-to-cart/${this.item.articleNumber}`
        ).then((r) => r.json());
        if (!content?.additionalNote) return;
        this.additionalNote = content.additionalNote.join('');
      } catch (error) {
        console.error(error); //eslint-disable-line
      }
    },
  },
};
</script>
