<template>
  <div
    v-if="hasEnergyLabel"
    class="energy-label-container"
  >
    <EnergyLabel
      ref="energyLabel"
      :energy-class="getEnergyClass(energyEfficiency)"
      class="energy-label-container"
      :products="transformedProducts"
      :alignment="datasheetAlignment"
      @click="handleLightboxOpen"
    />
    <Modal
      ref="lightbox"
      @close="handleLightboxClose"
    >
      <Slider>
        <template v-for="(bundledProduct, index) in bundledProductsWithEnergyEfficiency">
          <div
            :key="index"
            class="energy-label-slide"
          >
            <div class="energy-label-slide__head">
              <EnergyLabel
                :energy-class="getEnergyClass(bundledProduct.energyEfficiency)"
                class="energy-label-slide__head-label"
                :products="[extractedProducts.find((product) => !!product)]"
              />
              <strong>{{ bundledFamilyNameWithCount(bundledProduct.family.name) }}</strong>
            </div>
            <CdnImage
              v-if="hasImage(bundledProduct.energyEfficiency)"
              :alt="bundledProduct.family.name"
              :src="getImagePath(bundledProduct.energyEfficiency)"
            />
          </div>
        </template>
      </Slider>
    </Modal>
  </div>
</template>

<script>
import { attachmentUrl } from '@/lib/attachment-route';
import { EnergyLabel, Slider } from '@i22-td-smarthome/component-library';
import CdnImage from '@/components/cdn-image.vue';
import Modal from '@/components/modal.vue';
import { countBy, uniqBy } from 'lodash';
import {
  energyEfficiencyClasses,
  productsWithEnergyEfficiency,
  extractedProducts,
} from '@i22/rocket';

export default {
  name: 'EnergyLabelContainer',
  components: {
    EnergyLabel,
    Slider,
    CdnImage,
    Modal,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    datasheetAlignment: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'bottom', 'justify', 'left'].includes(value);
      },
    },
  },
  data() {
    return {
      lightboxLinkRef: null,
    };
  },
  computed: {
    hasEnergyLabel() {
      return this.energyEfficiencyClasses.length > 0;
    },
    energyEfficiency() {
      return this.energyEfficiencyClasses.find((efficiencyClass) => !!efficiencyClass);
    },
    extractedProducts() {
      if (!this.product) return [];
      return extractedProducts(this.product);
    },
    energyEfficiencyClasses() {
      return energyEfficiencyClasses(this.extractedProducts);
    },
    productsWithEnergyEfficiency() {
      return productsWithEnergyEfficiency(this.extractedProducts);
    },
    transformedProducts() {
      return this.extractedProducts.map((product) => ({
        name: product.name,
        energyEfficiency: {
          class: product?.energyEfficiency?.class,
          labelImagePath: this.getImagePath(product?.energyEfficiency),
          documentPath: this.getDocumentPath(product?.energyEfficiency),
        },
      }));
    },
    bundledProductsWithEnergyEfficiency() {
      return uniqBy(this.productsWithEnergyEfficiency, (p) => p.family.name);
    },
    bundledProductFamilyCounts() {
      return countBy(this.productsWithEnergyEfficiency, (p) => p.family.name);
    },
  },
  methods: {
    handleLightboxOpen(lightboxLinkRef) {
      if (lightboxLinkRef) {
        this.lightboxLinkRef = lightboxLinkRef.focusOrigin;
      }
      this.$tealium.link({
        wt_link_id: 'content.button.energy-label',
      });

      this.$refs.lightbox.open();
    },
    handleLightboxClose() {
      if (!this.lightboxLinkRef) {
        return;
      }

      this.lightboxLinkRef.focus();
    },
    getImagePath(energyEfficiency) {
      const labelImage = energyEfficiency?.labelImage;

      if (!labelImage) {
        return null;
      }

      return attachmentUrl(labelImage);
    },
    hasImage(energyEfficiency) {
      return !!this.getImagePath(energyEfficiency);
    },
    getDocumentPath(energyEfficiency) {
      const document = energyEfficiency?.document;

      if (!document) {
        return null;
      }

      return attachmentUrl(document);
    },
    getEnergyClass(energyEfficiency) {
      const { class: energyClass } = energyEfficiency;

      return energyClass;
    },
    bundledFamilyNameWithCount(familyName) {
      const count = this.bundledProductFamilyCounts[familyName] || 0;
      if (count <= 1) return familyName;
      return `${count} × ${familyName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';
.energy-label-slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &-label {
      line-height: 0.9;
      margin-right: 0.75rem;
      margin-top: -2px;
      @include font-size(24px, 30px);
    }
  }
}
</style>
