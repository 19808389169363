import { ref } from '@nuxtjs/composition-api';

export type ScreenTypes = 'largeDesktop' | 'desktop' | 'tablet' | 'mobile';

export const screenSize = ref<ScreenTypes>('mobile');
export const isMobileScreen = ref(true);
export const isTabletScreen = ref(false);
export const isDesktopScreen = ref(false);
export const isLargeDesktopScreen = ref(false);
export const isAtLeastTabletScreen = ref(false);
export const isAtLeastDesktopScreen = ref(false);
export const isAtLeastMediumDesktopScreen = ref(false);

const updateScreenSize = () => {
  const width = window.innerWidth;
  if (width >= 1441) {
    screenSize.value = 'largeDesktop';
  } else if (width >= 1024) {
    screenSize.value = 'desktop';
  } else if (width >= 640) {
    screenSize.value = 'tablet';
  } else {
    screenSize.value = 'mobile';
  }

  isMobileScreen.value = screenSize.value === 'mobile';
  isTabletScreen.value = screenSize.value === 'tablet';
  isDesktopScreen.value = screenSize.value === 'desktop';
  isLargeDesktopScreen.value = screenSize.value === 'largeDesktop';
  isAtLeastTabletScreen.value = width >= 640;
  isAtLeastDesktopScreen.value = width >= 1024;
  isAtLeastMediumDesktopScreen.value = width >= 1280;
};

const useScreenSize = () => ({
  screenSize,
  isMobileScreen,
  isTabletScreen,
  isDesktopScreen,
  isLargeDesktopScreen,
  isAtLeastTabletScreen,
  isAtLeastDesktopScreen,
  isAtLeastMediumDesktopScreen,
  updateScreenSize, // Export the update function
});

export default useScreenSize;
