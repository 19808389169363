function getTelekomContractLink(offer) {
  const pxsdbFeaturesArr = offer?.product?.pxsdbFeatures;
  const isProvidedByTelekom = offer.product?.stock?.origin === 'TELEKOM';
  if (pxsdbFeaturesArr?.length && isProvidedByTelekom) {
    // since 'i22_config_page' is usually in index 1 so I check it
    // first to potentially save the execution time of Array.find()
    const configObj =
      pxsdbFeaturesArr[1]?.key === 'i22_config_page'
        ? pxsdbFeaturesArr[1]
        : pxsdbFeaturesArr.find((el) => el.key === 'i22_config_page');
    if (configObj) return configObj.value;
    return null;
  }
  return null;
}

function getTelekomContractLinkText(path = null, hasSmartphoneCategory = false) {
  const isSmartphone =
    (path && path.split('/')?.[1] === 'smartphones-und-handys') || hasSmartphoneCategory;
  if (isSmartphone) return 'Zum Smartphone-Angebot mit Vertrag';
  return 'Zum Produkt-Angebot mit Vertrag';
}

export { getTelekomContractLink, getTelekomContractLinkText };
