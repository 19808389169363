import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import type { RootState } from '@/types/store-types';

export interface LoaderState {
  loading: number;
}

export const state = (): LoaderState => ({
  loading: 0,
});

export const mutations: MutationTree<LoaderState> = {
  START_LOADING(state) {
    state.loading += 1;
  },
  STOP_LOADING(state) {
    if (state.loading > 0) {
      state.loading -= 1;
    }
  },
};

export const getters: GetterTree<LoaderState, RootState> = {
  isLoaded(state) {
    return state.loading === 0;
  },
  isLoading(_state, getters) {
    return !getters.isLoaded;
  },
};

export const actions: ActionTree<LoaderState, RootState> = {
  stop({ commit }) {
    commit('STOP_LOADING');
  },
  start({ commit }) {
    commit('START_LOADING');
  },
};
