<template>
  <div class="info-dialog-content dialog-content">
    <div class="dialog-content__header">
      <span
        v-if="htmlSafe"
        v-html="message"
      ></span>
      <span
        v-else
        v-html="message"
      />
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <div class="grid__item grid__item--12 grid__item---m-6 grid__item--l-3 dialog__button">
        <button
          ref="okButton"
          class="btn btn--transparent btn--full dialog-content__button"
          @click="close(options)"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DialogContent from '@/mixins/dialog-content';

export default {
  name: 'InfoDialogContent',
  mixins: [
    DialogContent({
      htmlSafe: false,
      message: null,
      buttonText: 'OK',
    }),
  ],
  mounted() {
    this.$nextTick(() => {
      this.$refs.okButton?.focus();
    });
  },
};
</script>
