<template>
  <nav
    :class="{ 'mainnav--sticky': sticky }"
    class="mainnav"
  >
    <div class="mainnav__wrapper">
      <div class="mainnav__logo-wrapper">
        <nuxt-link
          ref="logo"
          :to="{ name: 'index' }"
          class="mainnav__logo"
          data-tealium-rel="navi-top.image.logo"
          title="Zur Startseite"
        >
          <SvgIcon
            :image="logo"
            no-icon-class
          />
        </nuxt-link>
      </div>

      <div class="mainnav__desktop-menus">
        <CustomerNavigation ref="customerNavigation" />
        <div class="mainnav__inner-menus">
          <PrimaryNavigation
            ref="navigation"
            :items="navigationItems"
          />

          <SecondaryNavigation
            @search="search"
            @toggle-mobile-nav="toggleMobileNav"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { urlifyTermAndSection } from '@/lib/search-helper';
import { mapGetters } from 'vuex';
import Inflections from '@/mixins/inflections';
import PrimaryNavigation from '@/components/navigation/primary-navigation.vue';
import SecondaryNavigation from '@/components/navigation/secondary-navigation.vue';
import logo from '@/assets/images/telekom-logo-magenta-bg.svg';
import CustomerNavigation from '@/components/customer-navigation.vue';
import ScrollAnimation from '@i22/scroll-animation';

export default {
  name: 'MainNavigation',
  components: {
    PrimaryNavigation,
    SecondaryNavigation,
    CustomerNavigation,
  },
  mixins: [Inflections, ScrollAnimation],
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapGetters('navigation', ['navigationItems']),
  },
  methods: {
    search(term) {
      if (!term) return;
      const route = urlifyTermAndSection(term, 'alle');
      this.$router.push(route);
    },
    toggleMobileNav(isOpen) {
      const body = document.querySelector('body');
      if (!body) return;
      if (isOpen) {
        body.classList.add('mobile-nav-open');
      } else {
        body.classList.remove('mobile-nav-open');
      }
    },
    initAnimations() {
      this.createMediaScrollTrigger({
        '(min-width: 1024px)': () => {
          this.createScrollTrigger({
            trigger: 'body',
            start: 'top+=70px top',
            toggleClass: 'nav-scrolled',
          });
        },
      });
    },
  },
  watch: {
    $route() {
      this.toggleMobileNav(false);
    },
  },
  mounted() {
    this.initAnimations();
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.mainnav {
  background-color: color(white);
  will-change: scroll-position;
  z-index: $z-index-navbar;
  user-select: none;
  height: 60px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
  @include mq($mq-sm) {
    height: 64px;
  }

  @include mq($mq-lg) {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .primary-navigation {
    @include mq(false, $mq-lg - 1) {
      box-shadow: 0 -1px 2px 0 rgb(0 0 0 / 15%);
    }

    @include mq($mq-lg) {
      display: inline-flex;
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: $grid-base * 0.5;
    justify-content: space-between;

    @include mq($mq-sm) {
      padding-inline: $grid-base;
    }

    @include mq($mq-lg) {
      width: 100%;
      margin: 0 auto;
      max-width: $max-navbar-width + $grid-base * 4;
      padding-inline: 2 * $grid-base;
      justify-content: flex-start;
    }
  }

  &__logo-wrapper {
    height: 100%;
    flex: 1 1 100%;
    display: flex;
    align-self: self-end;

    @include mq($mq-lg) {
      flex: initial;
      min-width: 96px;
      height: fit-content;
      margin-right: $grid-base * 1.5;
    }
    @include mq($mq-lg) {
      min-width: 138px;
    }
  }

  &__logo {
    display: block;
    height: 100%;
    width: fit-content;

    @include mq($mq-lg) {
      height: 96px;
      transition: height 0.4s $nav-timing-function;
    }

    > svg {
      height: 100%;
      width: auto;
    }
  }

  &__desktop-menus,
  &__inner-menus {
    height: 100%;
    @include mq($mq-lg) {
      width: 100%;
    }
  }

  &__desktop-menus {
    @include mq($mq-lg) {
      max-width: calc(100% - 156px);
    }
  }

  &__inner-menus {
    height: 100%;
    @include mq($mq-lg) {
      display: flex;
      justify-content: space-between;
      grid-gap: $grid-base * 1.5;
    }
  }
}
</style>

<style lang="scss">
@import 'assets/base';
body {
  &.nav-scrolled {
    .mainnav__logo {
      @include mq($mq-lg) {
        height: 70px;
      }
    }

    .nexus-pk-coin {
      @include mq($mq-lg) {
        top: $nav-shrink-offset;
      }
    }
  }

  &.mobile-nav-open {
    @include mq(false, $mq-lg - 1) {
      overflow: hidden;
    }
  }

  &:not(.mobile-nav-open) {
    .primary-navigation {
      display: none;
    }
  }
}

.nexus-pk-coin {
  @include mq($mq-lg) {
    max-width: $max-navbar-width;
    margin: 0 auto;
    transition: top 0.4s $nav-timing-function;
  }
  &__search-wrapper {
    margin-left: 64px + $grid-base;
    height: 100%;
    padding: 0 $grid-base;

    @include mq($mq-lg) {
      margin-left: $grid-base * 10;

      form {
        display: flex;
        align-items: center;
        height: 100%;
        margin-top: 0;
      }
    }
  }
}

// Search Result
.vue-portal-target {
  .nexus-pk-coin {
    // Search Field
    @include mq($mq-sm) {
      padding-top: 4px;
    }
    &__search-wrapper {
      background: $color-secondary;
      padding-inline: $grid-base;
    }

    // always show close button
    &__form button.nexus-pk-coin__clear-button {
      display: block;
    }

    &__form {
      margin-block: 11px;
      @include mq($mq-sm) {
        margin-block: $grid-base;
      }
    }
    &__content {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 20px;
      max-width: $mq-xl;
      margin-inline: auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      @include mq($mq-sm) {
        top: 64px;
      }
      @include mq($mq-lg) {
        top: 142px;
        bottom: auto;
        position: absolute;
      }

      > * {
        max-width: 100vw;
      }
    }
  }
}
</style>
