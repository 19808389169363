import baseGoliathRequest from './base-goliath-request';
import type { ListQuery, ListQueryVariables } from '@/lib/goliath/goliath.types';

// Ist type contains all fields for an offer in the list
export type Offer = NonNullable<NonNullable<ListQuery['offers']>['entries']>[number];

export default async (
  params: ListQueryVariables = {},
  progress = true,
  useCache = true
): Promise<ListQuery['offers']> =>
  baseGoliathRequest('/api/goliath/list', params, { entries: [] }, progress, useCache);
