/* eslint-disable no-underscore-dangle */
import { getPublicRuntimeConfig } from '../modules/runtime-config/load-runtime-config';
import type { PublicRuntimeConfig } from '../modules/runtime-config/runtime-config-type';

declare global {
  interface Window {
    __NUXT__: {
      config: PublicRuntimeConfig;
    };
  }
}

// set "baseUrl" to current URL from the browser.
if (process.client) {
  window.__NUXT__.config.baseUrl = window.location.origin;
}
/**
 * in client: the config is used from nuxt
 * in server: the config is loaded from the file "runtime-config.json"
 */
const runtimeConfig: PublicRuntimeConfig = process.client
  ? window.__NUXT__.config
  : getPublicRuntimeConfig();
export default runtimeConfig;

export const { buildDate } = runtimeConfig;
export const { mode } = runtimeConfig;
export const { baseUrl } = runtimeConfig;
export const { hostingAuth } = runtimeConfig;
export const { shopBackend } = runtimeConfig;
export const { shopConfirmationUrl } = runtimeConfig;
export const { telekomPaymentInformationUrl } = runtimeConfig;
export const { successUrlTelekom } = runtimeConfig;
export const { successUrlBrodos } = runtimeConfig;
export const { shopSuccessUrl } = runtimeConfig;
export const { shopErrorUrl } = runtimeConfig;
export const { telekomLoginSuccessUrl } = runtimeConfig;
export const { telekomLoginFailureUrl } = runtimeConfig;
export const { productImagePath } = runtimeConfig;
export const { goliathImagePath } = runtimeConfig;
// export const { sentry } = runtimeConfig;
export const { cloudFront } = runtimeConfig;
export const { search } = runtimeConfig;
export const { cdnUrl } = runtimeConfig;
