import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { RootState } from '@/types/store-types';

export interface ServerDateState {
  date: Date;
}

export const state = (): ServerDateState => ({
  date: new Date(),
});

export const mutations: MutationTree<ServerDateState> = {
  SET_SERVER_DATE(state) {
    state.date = new Date();
  },
};

export const actions: ActionTree<ServerDateState, RootState> = {
  setServerDate({ commit }) {
    commit('SET_SERVER_DATE');
  },
};

export const getters: GetterTree<ServerDateState, RootState> = {
  getServerDate(state) {
    return state.date;
  },
};
