import type { GetterTree, MutationTree } from 'vuex';
import type { RootState } from '@/types/store-types';

export type Staggered = {
  amount: number;
  percentage: number;
};

export type Rebate = {
  name?: string;
  description?: string | undefined;
  footnote?: string;
  staggereds?: Staggered[];
};

export type Rebates = Record<string, Rebate>;

export interface OfferState {
  rebates: Rebates;
  zeroPercentFinancing: string[];
}

interface OfferGettersTypes {
  rebates: Rebates;
  zeroPercentFinancing: string[];
}

export type OfferGetterTree = {
  [P in keyof OfferGettersTypes]: OfferGettersTypes[P];
};

export type OfferGetters = {
  [P in keyof OfferGettersTypes]: (
    state: OfferState,
    getters: OfferGetterTree
  ) => OfferGettersTypes[P];
};

export const state = (): OfferState => ({
  rebates: {},
  zeroPercentFinancing: [],
});

export const mutations: MutationTree<OfferState> = {
  SET_REBATES(state, rebates: Rebates) {
    state.rebates = rebates;
  },
  SET_ZERO_PERCENT_FINANCING(state, articleNumbers: string[]) {
    state.zeroPercentFinancing = articleNumbers;
  },
};

export const getters: GetterTree<OfferState, RootState> & OfferGetters = {
  rebates(state) {
    return state.rebates;
  },
  zeroPercentFinancing(state) {
    return state.zeroPercentFinancing;
  },
};
