import { createClientRedirectHandler } from '@i22/rocket';
import type { Middleware } from '@nuxt/types';

// Nuxt Middleware Handler
// @ts-ignore
const redirectHandler: Middleware = ({ $config }) => {
  const baseUrl =
    $config.cloudFront.host && $config.cloudFront.redirectsEnabled
      ? `//${$config.cloudFront.host}`
      : $config.baseUrl;
  return createClientRedirectHandler({
    baseUrl,
    cacheExpiryTime: 60 * 1000 /* 1 minute */,
  });
};

export default redirectHandler;
