import { render, staticRenderFns } from "./bookmark-indicator.vue?vue&type=template&id=5f27772a&scoped=true"
import script from "./bookmark-indicator.vue?vue&type=script&setup=true&lang=ts"
export * from "./bookmark-indicator.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./bookmark-indicator.vue?vue&type=style&index=0&id=5f27772a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f27772a",
  null
  
)

export default component.exports