export type BrandLogo = {
  logoBw?: string;
  logoColor?: string;
  unique?: boolean;
  smarthomeBrand?: boolean;
  color?: boolean;
};

export const brandLogos = {
  netatmo: {
    logoBw: '/api/content/images/brand-logos/8c9a12b2ea4bd387ef77b2c88dcefd81/netatmo-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/1ac66e499b3411aca5c95930367635ea/netatmo-kl.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 113,
      },
    },
    smarthomeBrand: true,
  },
  'd-link': {
    logoBw: '/api/content/images/brand-logos/5c1c1682dabab059c559c27d6b3fbaa8/d-link-logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 90,
      },
    },
    smarthomeBrand: true,
  },
  philips: {
    logoBw: '/api/content/images/brand-logos/ab4677eb3f33c3922d92730430b431b5/philips-hue-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/9d2cd736bfe1795dade216f2db5dab82/philips-hue-2.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 162,
      },
    },
    smarthomeBrand: true,
  },
  'smabit-bitron-video': {
    logoBw: '/api/content/images/brand-logos/a42857b956764231ca7b3ec954372f71/bitron-video-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/92cfa015cd21ea2eafec81278f017969/bitron-video.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 105,
      },
    },
    smarthomeBrand: true,
  },
  schellenberg: {
    logoBw: '/api/content/images/brand-logos/f00c97eceb88ceed31912eb6c27f42b4/schellenberg-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/3645befedd44b8159477f93c9241276d/schellenberg.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 78,
      },
    },
    smarthomeBrand: true,
  },
  tado: {
    logoBw: '/api/content/images/brand-logos/39554a3da1365a2e516a48847ca35811/tado.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 57,
      },
    },
    smarthomeBrand: true,
  },
  gardena: {
    logoBw: '/api/content/images/brand-logos/0722f0733f152cad8c1d3d845535ab95/gardena-sw.svg',
    logoColor: '/api/content/images/brand-logos/color/7f896f7aab92f3b1cd04b6d5341359c9/gardena.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 94,
      },
    },
    smarthomeBrand: true,
  },
  'paul-neuhaus': {
    logoBw:
      '/api/content/images/brand-logos/479b2f78e96dd033285fbf01a71a7e0c/neuhaus-lighting-group-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/1bbeec1471d9d1e642d19776d5afcb8b/neuhaus-lighting-group@2x.png',
    unique: true,
    measurements: {
      width: {
        offerTile: 63,
      },
    },
    smarthomeBrand: true,
  },
  tint: {
    logoBw: '/api/content/images/brand-logos/817e3ce86798db1f7128666916cbd1d8/tint-logo-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/743100d623ba2ed84a778fce46866804/tint-logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 17,
      },
    },
    smarthomeBrand: true,
  },
  'homematic-ip': {
    logoBw: '/api/content/images/brand-logos/7b543220103671016ce86a1b65622849/homematic-ip.svg',
    logoColor:
      '/api/content/images/brand-logos/2529d43be416e2a2e54203eca14c3744/Homematic-IP-Logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 133,
      },
    },
    smarthomeBrand: true,
  },
  ledvance: {
    logoBw: '/api/content/images/brand-logos/752391b0409068cd85ee73612ac7c52c/logo-ledvance-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/03a1719163e4384d62afc2bc80c56946/logo-ledvance.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 122,
      },
    },
    smarthomeBrand: true,
  },
  wiz: {
    logoBw: '/api/content/images/brand-logos/06fa298ce73eb4bc67108530ab18cbb2/wiz-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/8bb71c6810d2f293a484ee135d3b37af/WiZ-logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 53,
      },
    },
  },
  nuki: {
    logoBw: '/api/content/images/brand-logos/69bc67ba360ff6c6e8bd176ec40b3c9e/nuki-sw.svg',
    logoColor: '/api/content/images/brand-logos/color/73bb708ab484aebd6610139936522ccf/nuki.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 45,
      },
    },
    smarthomeBrand: true,
  },
  telekom: {
    logoColor: '/api/content/images/brand-logos/fafa4a0dc25b7d273f5fb144598507d1/t-logo.svg',
    unique: true,
    color: true,
    measurements: {
      width: {
        offerTile: 17,
      },
    },
    smarthomeBrand: true,
  },
  smarthome: {
    logoColor: '/api/content/images/brand-logos/fafa4a0dc25b7d273f5fb144598507d1/t-logo.svg',
    unique: false,
    color: true,
    measurements: {
      width: {
        offerTile: 17,
      },
    },
    smarthomeBrand: true,
  },
  eurotronic: {
    logoBw: '/api/content/images/brand-logos/1196fb20543ddc6f74c7215c479ac32e/eurotronic.svg',
    logoColor:
      '/api/content/images/brand-logos/color/f7a1e9ed5c2d2d81f6a1282b1d7996f9/eurotronic-logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 35,
      },
    },
    smarthomeBrand: true,
  },
  varta: {
    logoBw: '/api/content/images/brand-logos/ff3160f6f0103842f82da4b80ef362e7/logo-varta-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/dc81514b7a05a6a324111ecc54481e4d/logo-varta.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 125,
      },
    },
    smarthomeBrand: true,
  },
  intenso: {
    logoBw: '/api/content/images/brand-logos/2c23e46f1072ed8d816e3969f7a01269/intenso.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 83,
      },
    },
    smarthomeBrand: true,
  },
  ekey: {
    logoBw: '/api/content/images/brand-logos/2df3cd254eecc4100a3bf89907bccc69/ekey-logo-sw.png',
    logoColor:
      '/api/content/images/brand-logos/7862e0662b5abbec58b3d434dc02b626/ekey-logo-color.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 81,
      },
    },
    smarthomeBrand: true,
  },
  amazon: {
    logoBw: '/api/content/images/brand-logos/d57d6584dfc47ee8e23a285a5d4b8e61/logo-amazon.svg',
    logoColor:
      '/api/content/images/brand-logos/color/007796d638b0b6f5224041c20a259b18/amazon-farbe.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 68,
      },
    },
    smarthomeBrand: true,
  },
  ikea: {
    logoBw:
      '/api/content/images/brand-logos/614bc03ac68855f7c86a0783e907d806/ikea-logo-svg-vector.svg',
    logoColor:
      '/api/content/images/brand-logos/color/eb7482e63eff54c046b341c89405f7f4/ikea-logo-color.png',
    unique: true,
  },
  eufy: {
    logoBw: '/api/content/images/brand-logos/7797d1d400cb4e6680a5beeaf4d61c48/eufy-logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 41,
      },
    },
    smarthomeBrand: true,
  },
  hama: {
    logoBw: '/api/content/images/brand-logos/ff7a7b0893171e2cc5a1a2d79136a08d/hama-logo-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/a243cb88d97cbff213af2922c17d2e3c/hama-logo-color.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 75,
      },
    },
    smarthomeBrand: true,
  },
  xiaomi: {
    logoBw: '/api/content/images/brand-logos/a868742a1939708b725e61a56c4b6984/xiaomi-logo-sw.svg',
    logoColor:
      '/api/content/images/brand-logos/color/1bf1809f7207f8fff5c8585c50373d19/xiaomi-logo-color.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 20,
      },
    },
    smarthomeBrand: true,
  },
  mika: {
    logoBw: '/api/content/images/brand-logos/dda2754d72fc7eaccbbbb1675a17cb45/VREEDA_Logo.svg',
    logoColor: '/api/content/images/brand-logos/dda2754d72fc7eaccbbbb1675a17cb45/VREEDA_Logo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 104,
      },
    },
    smarthomeBrand: true,
  },
  aura: {
    logoBw: '/api/content/images/brand-logos/269f53d4cd16ed738fe18a7fd774bc00/aura_logo_black.png',
    unique: true,
    measurements: {
      width: {
        offerTile: 95,
      },
    },
    smarthomeBrand: true,
  },
  imou: {
    logoBw: '/api/content/images/brand-logos/040ea03edbfa9d21f77912ef77bf9308/imou_logo_black.svg',
    logoColor:
      '/api/content/images/brand-logos/733a106268827c947e39b8a756134f25/imou-logo-color.png',
    unique: true,
    measurements: {
      width: {
        offerTile: 80,
      },
    },
    smarthomeBrand: true,
  },
  eve: {
    logoBw: '/api/content/images/brand-logos/dbb20b95e314236fbd5c97169f539eb1/eve.svg',
    logoColor: '/api/content/images/brand-logos/dbb20b95e314236fbd5c97169f539eb1/eve.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 53,
      },
    },
    smarthomeBrand: true,
  },
  asus: {
    logoBw: '/api/content/images/brand-logos/f73f5dedda1f4c635179843558259de2/ASUS_Logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 93,
      },
    },
  },
  avm: {
    logoBw: '/api/content/images/brand-logos/e2383603ce192ed7bbce66825f59e834/avm-sw-min.svg',
    logoColor: '/api/content/images/brand-logos/3b866fde632c5194c1b483a1d13b53da/AVM-Logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 35,
      },
    },
  },
  belkin: {
    logoBw: '/api/content/images/brand-logos/1267d668af0b86c5d02d7ac03a07e379/Belkin-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 73,
      },
    },
  },
  alcatel: {
    logoBw: '/api/content/images/brand-logos/77b2df297a74d9ea4661b13e56f18df7/alcatel-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/93a22e30785e3642d64ce872abb407e0/Alcatel_Logo_last-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 54,
      },
    },
  },
  dell: {
    logoBw: '/api/content/images/brand-logos/b3d1a9521990b0253d4417f35598a65f/dell-sw-min.svg',
    logoColor: '/api/content/images/brand-logos/5eea7fa6a3090b9f742e651b1aff8b66/Dell_logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 63,
      },
    },
  },
  fairphone: {
    logoBw:
      '/api/content/images/brand-logos/7dba23c6a670c0fde3e4918cc7cf62fc/Fairphone_logo-1-min.svg',
    logoColor:
      '/api/content/images/brand-logos/1d338e7023a0d33aebd4cbf32d6ed5d2/Fairphone_logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 148,
      },
    },
  },
  nokia: {
    logoBw: '/api/content/images/brand-logos/697889f50109ffa535b2a1cf4f18beb4/nokia-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/7b56f4032a220f3a81187e106ca53e9c/Nokia_wordmark-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 118,
      },
    },
  },
  google: {
    logoBw: '/api/content/images/brand-logos/88d420f268428d3eb184d38c34dacaf7/google-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/7bbed22984d4cbfb6f7645177044af42/Google_2015_logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 60,
      },
    },
  },
  poly: {
    logoBw: '/api/content/images/brand-logos/7f9529c70ac77ec60003ad9fcf8cb6fa/poly-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/8438caebdf2acf9c4ece3d6bab05ceee/Poly_Inc._Logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 46,
      },
    },
  },
  teufel: {
    logoBw: '/api/content/images/brand-logos/da40a90ac29a568f36697faa47c42e56/teufel-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/61b8048e206bd19fdaa07d3251110958/Lautsprecher_Teufel_Logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 54,
      },
    },
  },
  reolink: {
    logoBw: '/api/content/images/brand-logos/380513a3459b440516ba854de67359f0/Reolink-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 77,
      },
    },
  },
  spigen: {
    logoBw: '/api/content/images/brand-logos/135dc2f82f74ed500d19c6c11989f98c/spigen-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/df8e44cc67d9737e1c1f110fc6b504c3/Spigen_logo-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 92,
      },
    },
  },
  samsung: {
    logoBw: '/api/content/images/brand-logos/e180e132a0eb2a8521d02489bc60e07c/samsung-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/09d9f03393a8c3c74ad06e8b283a433f/Samsung_wordmark-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 130,
      },
    },
  },
  zte: {
    logoBw: '/api/content/images/brand-logos/080bb6accf1756a3a5c452a19261dcbb/ZTE-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 41,
      },
    },
  },
  nothing: {
    logoBw: '/api/content/images/brand-logos/2c9af2d61ef44d655fa9f38814e692b9/Nothing-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 111,
      },
    },
  },
  sandisk: {
    logoBw: '/api/content/images/brand-logos/71b317660455b5f0ce7331eb6db320c2/sandisk-sw-min.svg',
    logoColor:
      '/api/content/images/brand-logos/5f0251ef1d389fddffac769c71f1692a/SanDisk_Logo_2007-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 100,
      },
    },
  },
  agood: {
    logoBw:
      '/api/content/images/brand-logos/d320753f56b6f2f2e74a58be5b447b43/agood-logo-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 143,
      },
    },
  },
  anker: {
    logoBw: '/api/content/images/brand-logos/3f16669f1187d9320ce575c8231f3de1/anker-sw-min.svg',
    logoColor: '/api/content/images/brand-logos/88a51adaef5a7ad519b8a089b683f22b/anker-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 83,
      },
    },
  },
  apple: {
    logoBw: '/api/content/images/brand-logos/5c6533b9f740ac15efa390058a8c427c/apple-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 17,
      },
    },
  },
  'black-rock': {
    logoBw:
      '/api/content/images/brand-logos/1e3ed84db81197fbbafb587d261a9804/black-rock-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 49,
      },
    },
  },
  epico: {
    logoBw: '/api/content/images/brand-logos/6a83bb2884be766f210980fc7241e0b7/epico-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 68,
      },
    },
  },
  cellularline: {
    logoBw:
      '/api/content/images/brand-logos/d9e9e92159584b1213b64a88399169c7/cellularline-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 46,
      },
    },
  },
  huawei: {
    logoBw: '/api/content/images/brand-logos/fe49f84781c8b1ce56922bc6752f1431/huawei-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 91,
      },
    },
  },
  fitbit: {
    logoBw: '/api/content/images/brand-logos/a4a3684cedbd2c67ca9bee155c20afa7/fitbit-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 77,
      },
    },
  },
  jabra: {
    logoBw: '/api/content/images/brand-logos/7d8078572dfa33dc070991ea085e4e2c/jabra-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 85,
      },
    },
  },
  lenovo: {
    logoBw: '/api/content/images/brand-logos/2aa612fd8ae196111696241a6c4cdc1f/lenovo-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 96,
      },
    },
  },
  logitech: {
    logoBw: '/api/content/images/brand-logos/fd1e8ca7d4140692ffde31a6cd35d7e8/logitech-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 66,
      },
    },
  },
  microsoft: {
    logoBw: '/api/content/images/brand-logos/7a0d0f9af107ad491ddf338b0b6027ea/microsoft-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 94,
      },
    },
  },
  lg: {
    logoBw: '/api/content/images/brand-logos/823ad534532d48333a565b2c49483f57/lg-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 45,
      },
    },
  },
  motorola: {
    logoBw: '/api/content/images/brand-logos/3293ea16781ef8874e6f239f127605a5/motorola-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 87,
      },
    },
  },
  sony: {
    logoBw: '/api/content/images/brand-logos/03ef646252d20ce84ab10fd6368bc81f/sony-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 113,
      },
    },
  },
  skullcandy: {
    logoBw:
      '/api/content/images/brand-logos/e9629edabbda96108c8dd25ddd21f409/skullcandy-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 109,
      },
    },
  },
  sennheiser: {
    logoBw:
      '/api/content/images/brand-logos/e39c76eb15b26334c8a55ae86b24c21e/sennheiser-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 148,
      },
    },
  },
  soundcore: {
    logoBw: '/api/content/images/brand-logos/bce0f2578997283ec7d395a42d4805c3/soundcore-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 95,
      },
    },
  },
  tcl: {
    logoBw: '/api/content/images/brand-logos/34d1d2af96ecaf32310d62f8e33a39e5/tcl-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 64,
      },
    },
  },
  skross: {
    logoBw: '/api/content/images/brand-logos/7b3fdeb5d4661543047bc8814011619d/skross-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 100,
      },
    },
  },
  'ultimate-ears': {
    logoBw:
      '/api/content/images/brand-logos/bf3cfcbf7fc327e9be34500eda2bb9a3/ultimate-ears-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 43,
      },
    },
  },
  wacom: {
    logoBw: '/api/content/images/brand-logos/43b0313e02c9ef3c8a53177a04e1ce44/wacom-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 113,
      },
    },
  },
  syllucid: {
    logoBw: '/api/content/images/brand-logos/7c8f024b918e65332413b1bacf0a7af0/syllucid-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 79,
      },
    },
  },
  xlayer: {
    logoBw: '/api/content/images/brand-logos/008a8d6880226d6c30c72a0c3e2ef536/xlayer-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 49,
      },
    },
  },
  tech21: {
    logoBw: '/api/content/images/brand-logos/b2fe92c85d0230196d9b1d11c636faf4/tech-21-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 44,
      },
    },
  },
  'white-diamonds': {
    logoBw:
      '/api/content/images/brand-logos/b79b1b3d08044711ebdfaa3fbd215e20/white-diamonds-sw-min.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 53,
      },
    },
  },
  aeg: {
    logoBw: '/api/content/images/brand-logos/fb19dbbf83dc5706c40e6f05a694b2fa/aeg.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 53,
      },
    },
  },
  agi: {
    logoBw: '/api/content/images/brand-logos/0f69c742082ee4c4d303520158bf9530/agi.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 31,
      },
    },
  },
  dbramante1928: {
    logoBw: '/api/content/images/brand-logos/3cecb46e7b510f15ab11314a20a435dc/dbramante.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 147,
      },
    },
  },
  deqster: {
    logoBw: '/api/content/images/brand-logos/0429358de38052456ebda4521751f0cf/deqster-1.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 125,
      },
    },
  },
  displex: {
    logoBw: '/api/content/images/brand-logos/663af223e3afc3ac227b6b9cd1f2b62b/displex.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 75,
      },
    },
  },
  'fresh-n-rebel': {
    logoBw: '/api/content/images/brand-logos/84c6836dab485bf51c9a8b2b3cb0e46d/fresh-n-rebel.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 37,
      },
    },
  },
  nevox: {
    logoBw: '/api/content/images/brand-logos/17c16a84757d29c785e5cbb60e9a7ded/nevox.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 134,
      },
    },
  },
  omelcom: {
    logoBw: '/api/content/images/brand-logos/a4c6f9df74cf2de9afd0fbadb6b0fb14/omelcom.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 52,
      },
    },
  },
  panzerglass: {
    logoBw: '/api/content/images/brand-logos/004117f29eeff4a861a7661f247572d2/panzerglass.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 171,
      },
    },
  },
  'peter-jaeckel': {
    logoBw: '/api/content/images/brand-logos/438285abd004d75f8ae23685cfd8ae40/peter-jaeckel.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 154,
      },
    },
  },
  pivo: {
    logoBw: '/api/content/images/brand-logos/217c0f83db4cfddefbd4684c91c0c0ff/pivo.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 43,
      },
    },
  },
  vonmaehlen: {
    logoBw: '/api/content/images/brand-logos/d72fd13278ed62fcfd0a070230e97b43/vonmaehlen.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 193,
      },
    },
  },
  woodcessories: {
    logoBw: '/api/content/images/brand-logos/3fdb392693d57be052e2ab145d842d0b/woodcessories.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 246,
      },
    },
  },
  xplora: {
    logoBw: '/api/content/images/brand-logos/e1cf27a39c316b5db78cdcc7cdb8f633/xplora.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 60,
      },
    },
  },
  zens: {
    logoBw: '/api/content/images/brand-logos/7c3ec359d0172c1b21ff616530684873/zens.svg',
    unique: true,
    measurements: {
      width: {
        offerTile: 120,
      },
    },
  },
};
