import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

const WIDTH_DEBOUNCE_MS = 200;

function hasWindow() {
  return typeof window !== 'undefined';
}

export default {
  data() {
    return {
      resizeHandler: null,
      hasVerticalScrollbar: false,
    };
  },
  created() {
    if (!hasWindow()) return;
    this.resizeHandler = debounce(() => {
      this.setViewportSize();
    }, WIDTH_DEBOUNCE_MS);
    this.setViewportSize();
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    if (!hasWindow()) return;
    window.removeEventListener('resize', this.resizeHandler);
    this.resizeHandler = null;
  },
  computed: {
    ...mapGetters('backdrop', {
      backdropIsActive: 'isActive',
      backdropIsGloballyActive: 'isGloballyActive',
    }),
    ...mapGetters('downtimes', ['maintenanceDowntime']),
    isPageWithDowntime() {
      return [
        'aktionsangebote',
        'shop-warenkorb',
        'shop-kundenformular',
        'shop-zusammenfassung',
        'shop-list',
        'geraete-slug',
        'magenta-tv-hardware',
        'preisvorteile-sichern',
      ].includes(this.$route?.name);
    },
  },
  async mounted() {
    await this.checkForDowntime();
    // In all cart pages, a fresh cart is fetched. So we do not need this here.
    if (!this.$route.path?.startsWith('/shop/') && !this.maintenanceDowntime) {
      await this.initCart();
    }
  },
  watch: {
    async $route() {
      await this.checkForDowntime();
    },
  },
  methods: {
    ...mapActions('downtimes', ['fetchDowntimes']),
    ...mapActions('cart', ['initCart']),
    setViewportSize() {
      if (!hasWindow()) return;

      this.hasVerticalScrollbar = window.innerWidth > document.documentElement.clientWidth;
    },
    async checkForDowntime() {
      if (!this.isPageWithDowntime) return; // When we are not in shop, we do not need to fetch downtimes.
      await this.fetchDowntimes();
      if (this.$route?.name === 'maintenance') return;

      if (this.maintenanceDowntime) {
        await this.$router.push('/maintenance');
      }
    },
  },
  head() {
    const htmlClasses = ['app'];
    if (this.backdropIsActive) {
      htmlClasses.push('app--has-backdrop');
    }
    if (this.backdropIsGloballyActive) {
      htmlClasses.push('app--has-global-backdrop');
    }
    if (this.hasVerticalScrollbar) {
      htmlClasses.push('app--has-vertical-scrollbar');
    }
    return { htmlAttrs: { class: htmlClasses.join(' ') } };
  },
};
