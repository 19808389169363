<template>
  <div class="scroll-to-top-button show-from-extra-large">
    <transition name="scroll-to-top-button--fade">
      <button
        v-show="visible"
        class="scroll-to-top-button__button"
        @click="scrollTowardsTop"
      >
        <svg-icon
          :image="arrowScrollTop"
          class="icon scroll-to-top-button__icon"
        />
      </button>
    </transition>
  </div>
</template>

<script>
import arrowScrollTop from '@/assets/images/arrow-scroll-up.svg';
// tag::delayed-steps[]
import DelayedSteps from '@/mixins/delayed-steps';

export default {
  name: 'ScrollToTopButton',
  mixins: [
    DelayedSteps, // <2>
  ],
  props: {
    // specifies when the "back to top" arrow should become visible
    showButtonBelow: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      arrowScrollTop,
      visible: false,
    };
  },
  mounted() {
    this.handleScrollEvent();
    window.addEventListener('scroll', this.handleScrollEvent);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScrollEvent);
  },
  methods: {
    handleScrollEvent() {
      this.visible = window.pageYOffset > parseInt(this.showButtonBelow, 10);
    },
    scrollTowardsTop() {
      const currentYOffset = window.pageYOffset;
      if (currentYOffset > 0) {
        const newYOffset = Math.floor(currentYOffset - currentYOffset / 5);
        window.scrollTo(0, newYOffset);
        this.registerNextStep(this.scrollTowardsTop);
      } else {
        this.allStepsDone();
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/base';

.scroll-to-top-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  &--fade-enter-active,
  &--fade-leave-active {
    transition: opacity 0.7s;
  }

  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }

  &__container {
    overflow: visible;
    position: relative;
  }

  &__button {
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    z-index: 100;
    cursor: pointer;
    width: 38px;
    height: 38px;
    border: 1px solid color(grey, 12000);
    color: color(grey, 12000);
    padding: 5px 0 0 0;
    position: absolute;
    bottom: 50px;
    right: 10px;
    z-index: 50;
  }

  &__icon {
    width: 10px;
    height: 18px;
  }
}
</style>
