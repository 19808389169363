<template>
  <OfferTileList :offers="offers">
    <template #default="{ offer, index }">
      <OfferTileContainer
        v-if="offer.product"
        :offer="offer"
        :tag-title="tagTitle"
        :lazy="index > 3"
      />
    </template>
    <template #beforeOfferTiles>
      <slot name="beforeOfferTiles" />
    </template>
    <template #afterOfferTiles>
      <slot name="afterOfferTiles" />
    </template>
  </OfferTileList>
</template>

<script>
import { OfferTileList } from '@i22-td-smarthome/component-library';

import OfferTileContainer from '@/components/offer-tile-container.vue';

export default {
  name: 'OfferTilesContainer',
  components: {
    OfferTileContainer,
    OfferTileList,
  },
  props: {
    offers: {
      type: Array,
      required: true,
    },
    tagTitle: { type: String, default: 'h2' },
  },
};
</script>
