import type { FetchError } from 'ofetch';
import type { BackendError } from '@/lib/shop-api';
import Dialog from '@/helpers/dialog';
import ErrorParser from '@/lib/error-parser';

type ItemNameCallback = (articleNumber: string) => string;

const FALLBACK_MESSAGE = 'Hoppla! Da ist etwas schiefgelaufen. Bitte versuche es noch einmal.';

export const getProductAddToCartErrorMessage = (
  articleNumbers: string[],
  errorType: string,
  itemNameCallback?: ItemNameCallback
) => {
  if (articleNumbers.length === 0) return undefined;

  const names = articleNumbers.map((articleNumber) => {
    if (itemNameCallback) return itemNameCallback(articleNumber);
    return itemNameCallback;
  });
  const namesAsHTML = `<strong>${names.join('</strong>, <strong>')}</strong>`;
  switch (errorType) {
    case 'taken':
      return `Folgende Produkte befanden sich bereits im Warenkorb und wurden daher nicht erneut hinzugefügt:<br>${namesAsHTML}`;
    case 'not_found':
      return `Folgende Produkte wurden nicht gefunden und daher nicht zum Warenkorb hinzugefügt:<br>${namesAsHTML}`;
    default:
      return undefined;
  }
};

export const handleCartErrors = async (
  error: FetchError<BackendError> | Error,
  itemNameCallback?: ItemNameCallback,
  fallbackMessage = FALLBACK_MESSAGE
) => {
  // Show Dialog by normal errors (caused by JS).
  // @ts-ignore
  if (!error?.data && error?.status !== 404) {
    Dialog.info(fallbackMessage, { type: 'error' });
    return;
  }

  // @ts-ignore
  let errors = (error.data?.errors || {}) as BackendError;

  // 404 means sold out
  // @ts-ignore
  if (error?.status === 404) {
    errors = { cart_validations: [{ error: ['not_available'] }] };
  }

  // Initializing error buckets
  const articleNumbersNotFound: string[] = [];
  const articleNumbersTaken: string[] = [];
  // @ts-ignore
  const others: BackendError = [];

  // Iterate through the keys in the errors object
  Object.keys(errors).forEach((errorType) => {
    // Iterate through the array of errors for the current key
    errors[errorType].forEach((errorEntry) => {
      if (!errorEntry.value) {
        if (!others[errorType]) others[errorType] = [];
        others[errorType].push(errorEntry);
        return;
      }
      // Group errors based on their type
      switch (errorEntry.error) {
        case 'not_found':
          articleNumbersNotFound.push(errorEntry.value as string);
          break;
        case 'taken':
          articleNumbersTaken.push(errorEntry.value as string);
          break;
        default:
          if (!others[errorType]) others[errorType] = [];
          others[errorType].push(errorEntry);
          break;
      }
    });
  });

  const messagesToShow = [
    getProductAddToCartErrorMessage(articleNumbersTaken, 'taken', itemNameCallback),
    getProductAddToCartErrorMessage(articleNumbersNotFound, 'not_found', itemNameCallback),
  ].filter(Boolean);

  if (Object.keys(others).length > 0) {
    messagesToShow.push(...ErrorParser.getMessagesFlatten(others, fallbackMessage));
  }

  if (messagesToShow.length === 0) {
    messagesToShow.push(FALLBACK_MESSAGE);
  }
  await Dialog.info(messagesToShow.join('<br><br>'), { htmlSafe: true, type: 'warning' });
};
