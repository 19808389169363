import startsWith from 'lodash/startsWith';
import { baseUrl } from '@/lib/public-runtime-config';
import Vue from 'vue';

const EXTERNAL_PREFIXES = ['http://', 'https://', '//'];

function isExternalUrl(url) {
  const urlHasProtocol = EXTERNAL_PREFIXES.some((prefix) => startsWith(url, prefix));
  if (!urlHasProtocol) {
    return false;
  }
  return !startsWith(url, baseUrl);
}

Vue.prototype.$redirect = function redirect(pathOrUrl) {
  if (isExternalUrl(pathOrUrl)) {
    window.location.href = pathOrUrl;
  } else if (startsWith(pathOrUrl, baseUrl)) {
    const path = pathOrUrl.slice(baseUrl.length);
    this.$router.push({ path });
  } else {
    this.$router.push({ path: pathOrUrl });
  }
};
